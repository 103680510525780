import common from '../common';
import errors from './errors';
import features from './features';
import themes from './theme';

export default {
  ...common,

  mobileTicket: 'Mobile Ticket',
  ...features,

  save: 'Speichern',

  username: 'Nutzername',
  password: 'Passwort',
  login: 'Anmeldung',
  required: 'Erforderlich',

  light: 'Licht',
  dark: 'Dunkel',

  rememberMe: 'Mich erinnern',

  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Datenschutz-Bestimmungen',

  customer: 'Kunde',
  project: 'Projekt',

  isBillable: 'Abrechenbar',
  dispatchDateTime: 'Datum',
  orderId: 'Befehl',
  ticketId: 'Fahrkarte',
  location: 'Ort',
  vehicleTypeName: 'Fahrzeug',
  product: 'Produkt',
  salesTax: 'Mehrwertsteuer',
  totalPrice: 'Gesamtpreis',
  extendedPrice: 'Erweiterter Preis',
  destination: 'Reiseziel',
  productId: 'Product ID',
  statusCode: 'Status',
  vehicleTypeId: 'Vehicle Type ID',
  locationId: 'Location ID',
  customerId: 'Customer ID',
  projectId: 'Project ID',
  dispatchTime: 'Abfertigungszeit',

  showAll: 'Zeige alles',
  hideAll: 'Versteck alles',
  undoChanges: 'Änderungen rückgängig machen',
  resetColumns: 'Spalten zurücksetzen',

  displayRowCount: `{count, plural, 
    =0 {Anzeige von # Zeilen} 
    one {# Zeile anzeigen} 
    other {Anzeige von # Zeilen} 
  }`,

  columnFilters: 'Spaltenfilter',

  visible: 'Sichtbar',
  hidden: 'Versteckt',

  itemDetail: 'Artikeldetail',

  quantity: 'Anzahl',

  unloadingMethod: 'Entlademethode',

  time: 'Zeit',
  eventStatus: 'Ereignisstatus',

  MTQ_abbr: 'm\u00B3',
  EA_abbr: 'EA',
  LTR_abbr: 'L',
  YDQ_abbr: 'CY',
  GLL_abbr: 'GAL',

  MTQ_text: 'Kubikmeter',
  M3_text: 'Kubikmeter',
  EA_text: 'Jeder',
  LTR_text: 'Liter',
  YDQ_text: 'Kubikmeter',
  GLL_text: 'Gallonen',
  ACR_text: 'Hektar',
  HUR_text: 'Stunde',
  INH_text: 'Zoll',
  FOT_text: 'Versfuß',
  YRD_text: 'Hof',
  MMT_text: 'Millimeter',
  CMT_text: 'Zentimeter',
  MTR_text: 'Meter',
  SMI_text: 'Meile (Statut Meile)',
  KMT_text: 'Kilometer',
  INK_text: 'Quadratzoll',
  FTK_text: 'Quadratfuß',
  YDK_text: 'Quadratischer Hof',
  MIK_text: 'Quadratmeile',
  CMK_text: 'Quadratzentimeter',
  MTK_text: 'Kubikmeter',
  KMK_text: 'Quadratkilometer',
  INQ_text: 'Kubikzoll',
  MMQ_text: 'Kubikmillimeter',
  FTQ_text: 'Kubikfuß',
  CMQ_text: 'Kubikzentimeter',
  OZA_text: 'Flüssigunzen',
  PT_text: 'Pint',
  QT_text: 'Quart',
  MLT_text: 'Milliliter',
  ONZ_text: 'Unze',
  LBR_text: 'Pfund',
  STN_text: 'Tonne',
  GRM_text: 'Gramm',
  KGM_text: 'Kilogramm',
  TNE_text: 'Tonne',
  PS_text: 'PSI',
  MPA_text: 'Megapascal',
  C56_text: 'Newton pro Quadratmillimeter',
  C62_text: 'Einer',
  NL_text: 'Belastung',
  MT_text: 'Matte',
  P1_text: 'Prozent',
  CEL_text: 'Grad Celsius',
  FAH_text: 'Grad Fahrenheit',
  BLL_text: 'Fass',
  CLT_text: 'Zentiliter',
  DLT_text: 'Deziliter',
  GLI_text: 'Gallone',
  '2U_text': 'Megagramm',
  SA_text: 'Sack',
  '60_text': 'Prozent Gewicht',
  BG_text: 'Tasche',
  SEC_text: 'Zweite',
  MIN_text: 'Minute',
  '64_text': 'Pfund pro Quadratzoll - Messgerät',
  SP_text: 'Regalpaket',

  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  M3: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  LTR: `{value, plural,
    =0 {0 Liter}
    one {1 Liter}
    other {# Liter}
  }`,

  TO: `{value, plural,
    =0 {0 Tonnen}
    one {1 Tonne}
    other {# Tonnen}
  }`,

  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,

  GLL: `{value, plural,
    =0 {0 Gallonen}
    one {1 Gallone}
    other {# Gallonen}
  }`,

  EA: `{value, plural,
    =0 {0 Jeder}
    one {1 Jeder}
    other {# Jeder}
  }`,

  MIN: `{value, plural,
    =0 {0 Minuten}
    one {1 Minute}
    other {# Minuten}
  }`,

  ACR: `{value, plural,
    =0 {0 Hektar}
    one {1 Acre}
    other {# Hektar}
  }`,
  HUR: `{value, plural,
    =0 {0 Std}
    one {1 Stunde}
    other {# Std}
  }`,
  INH: `{value, plural,
    =0 {0 Zoll}
    one {1 Zoll}
    other {# Zoll}
  }`,
  FOT: `{value, plural,
    =0 {0 Fuß}
    one {1 Versfuß}
    other {# Fuß}
  }`,
  YRD: `{value, plural,
    =0 {0 Meter}
    one {1 Hof}
    other {# Meter}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimeter}
    one {1 Millimeter}
    other {# Millimeter}
  }`,
  CMT: `{value, plural,
    =0 {0 Zentimeter}
    one {1 Zentimeter}
    other {# Zentimeter}
  }`,
  MTR: `{value, plural,
    =0 {0 Meter}
    one {1 Meter}
    other {# Meter}
  }`,
  SMI: `{value, plural,
    =0 {0 Meilen (Statutmeilen)}
    one {1 Meilen (Statut Mile)}
    other {# Meilen (Statutmeilen)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometer}
    one {1 Kilometer}
    other {# Kilometer}
  }`,
  INK: `{value, plural,
    =0 {0 Quadratzoll}
    one {1 Quadratzoll}
    other {# Quadratzoll}
  }`,
  FTK: `{value, plural,
    =0 {0 Quadratfuß}
    one {1 Quadratfuß}
    other {# Quadratfuß}
  }`,
  YDK: `{value, plural,
    =0 {0 Quadratmeter}
    one {1 Quadratischer Hof}
    other {# Quadratmeter}
  }`,
  MIK: `{value, plural,
    =0 {0 Quadratmeilen}
    one {1 Quadratmeile}
    other {# Quadratmeilen}
  }`,
  CMK: `{value, plural,
    =0 {0 Quadratzentimeter}
    one {1 Quadratzentimeter}
    other {# Quadratzentimeter}
  }`,
  MTK: `{value, plural,
    =0 {0 Kubikmeter}
    one {1 Kubikmeter}
    other {# Kubikmeter}
  }`,
  KMK: `{value, plural,
    =0 {0 Quadratkilometer}
    one {1 Quadratkilometer}
    other {# Quadratkilometer}
  }`,
  INQ: `{value, plural,
    =0 {0 Kubikzoll}
    one {1 Kubikzoll}
    other {# Kubikzoll}
  }`,
  MMQ: `{value, plural,
    =0 {0 Kubikmillimeter}
    one {1 Kubikmillimeter}
    other {# Kubikmillimeter}
  }`,
  FTQ: `{value, plural,
    =0 {0 Kubische Füße}
    one {1 Kubikfuß}
    other {# Kubische Füße}
  }`,
  CMQ: `{value, plural,
    =0 {0 Kubikzentimeter}
    one {1 Kubikzentimeter}
    other {# Kubikzentimeter}
  }`,
  OZA: `{value, plural,
    =0 {0 Flüssige Unzen}
    one {1 Flüssigunzen}
    other {# Flüssige Unzen}
  }`,
  PT: `{value, plural,
    =0 {0 Pints}
    one {1 Pint}
    other {# Pints}
  }`,
  QT: `{value, plural,
    =0 {0 Quarts}
    one {1 Quart}
    other {# Quarts}
  }`,
  MLT: `{value, plural,
    =0 {0 Milliliter}
    one {1 Milliliter}
    other {# Milliliter}
  }`,
  ONZ: `{value, plural,
    =0 {0 Unzen}
    one {1 Unze}
    other {# Unzen}
  }`,
  LBR: `{value, plural,
    =0 {0 Pfund}
    one {1 Pfund}
    other {# Pfund}
  }`,
  STN: `{value, plural,
    =0 {0 Tonnen}
    one {1 Tonne}
    other {# Tonnen}
  }`,
  GRM: `{value, plural,
    =0 {0 Gramm}
    one {1 Gramm}
    other {# Gramm}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilogramm}
    one {1 Kilogramm}
    other {# Kilogramm}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnen}
    one {1 Tonne}
    other {# Tonnen}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    one {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascals}
    one {1 Megapascal}
    other {# Megapascals}
  }`,
  C56: `{value, plural,
    =0 {0 Newton pro Quadratmillimeter}
    one {1 Newton pro Quadratmillimeter}
    other {# Newton pro Quadratmillimeter}
  }`,
  C62: `{value, plural,
    =0 {0 Einer}
    one {1 Einer}
    other {# Einer}
  }`,
  NL: `{value, plural,
    =0 {0 Ladungen}
    one {1 Belastung}
    other {# Ladungen}
  }`,
  MT: `{value, plural,
    =0 {0 Matten}
    one {1 Matte}
    other {# Matten}
  }`,
  P1: `{value, plural,
    =0 {0 Prozent}
  one {1 Prozent}
  other {# Prozent}
}`,
  CEL: `{value, plural,
    =0 {0 Grad Celsius}
  one {1 Grad Celsius}
  other {# Grad Celsius}
}`,
  FAH: `{value, plural,
    =0 {0 Grad Fahrenheit}
  one {1 Grad Fahrenheit}
  other {# Grad Fahrenheit}
}`,
  BLL: `{value, plural,
    =0 {0 Fässer}
  one {1 Fass}
  other {# Fässer}
}`,
  CLT: `{value, plural,
    =0 {0 Zentiliter}
  one {1 Zentiliter}
  other {# Zentiliter}
}`,
  DLT: `{value, plural,
    =0 {0 Deziliter}
  one {1 Deziliter}
  other {# Deziliter}
}`,
  GLI: `{value, plural,
    =0 {0 Gallonen}
  one {1 Gallone}
  other {# Gallonen}
}`,
  '2U': `{value, plural,
    =0 {0 Megagramm}
  one {1 Megagramm}
  other {# Megagramm}
}`,
  SA: `{value, plural,
    =0 {0 Säcke}
  one {1 Sack}
  other {# Säcke}
}`,
  60: `{value, plural,
    =0 {0 Prozent Gewicht}
  one {1 Prozent Gewicht}
  other {# Prozent Gewicht}
}`,
  BG: `{value, plural,
    =0 {0 Taschen}
  one {1 Tasche}
  other {# Taschen}
}`,
  SEC: `{value, plural,
    =0 {0 Sekunden}
  one {1 Zweite}
  other {# Sekunden}
}`,
  64: `{value, plural,
    =0 {0 Pfund pro Quadratzoll - Messgerät}
  one {1 Pfund pro Quadratzoll - Messgerät}
  other {# Pfund pro Quadratzoll - Messgerät}
}`,
  SP: `{value, plural,
    =0 {0 Regalpakete}
  one {1 Regalpaket}
  other {# Regalpakete}
}`,

  mixIdLabel: 'Mischung/Beschreibung',
  orderedQtyLabel: 'Bestellte Menge',
  loadedQtyLabel: 'Geladene Menge',

  addedByCustomer: 'Added By Customer',

  PRINTED: 'Gebucht',
  LOADING_STARTED: 'Beginnen Sie mit dem Laden',
  LOADING_COMPLETE: 'Beenden Sie das Laden',
  TO_JOB: 'Linkes Werk',
  ARRIVE_JOB: 'Standort ankommen',
  UNLOADING: 'Entladen starten',
  END_UNLOADING: 'Entladen beenden',
  LEAVE_JOB: 'Job verlassen',
  IN_YARD: 'Im Hof',
  PUMPING_STARTED: 'Pumpe starten',
  PUMPING_COMPLETE: 'Pumpe beenden',
  READY_TO_PUMP: 'Bereit zum Pumpen',
  FIRST_WCCONTACT: 'Erster Wasser-Zement-Kontakt',

  plantName: 'Pflanzenname',
  plantAddress: 'Werksadresse',
  loadingScheduleStart: 'Geplante Ladezeit',

  deliveryAddress: 'Lieferadresse',
  phoneNumber: 'Telefonnummer',
  unloadingScheduleStart: 'Geplantes Startdatum und Uhrzeit des Entladens',
  agreedUnloading: 'Vereinbarte Entladezeiten',

  deliveryInformation: 'Lieferinformationen',
  next: 'kommend',

  material: 'Stoff',
  loadingPoint: 'Lade-Punkt',
  unloadingPoint: 'Entladestelle',

  comment: 'Kommentar',

  sapDeliveryNumber: 'SAP Delivery number (BCC Number)',
  salesOrderNumber: 'Sales order number and item line',
  wcDateTime: 'Date and time W/C contact',
  po: 'PO Number',
  orderedByName: 'Order placed by name',
  orderedByPhone: 'Order placed by phone number',
  resourceId: 'Resource ID',
  totalLoadedQty: 'Total loaded qty including this load',
  spacing: 'Load space',
  deliveryFlow: 'Delivery flow',
  timeOnSite: 'Total on site time/ordered unloading time',
  salesText: 'Sales text (material ID)',
  strengthClass: 'Strength class',
  environmentExposure: 'Environment exposure',
  metExposureClasses: 'Met exposure classes',
  dmax: 'Dmax',
  standardCert: 'Standard (certification)',
  workabilityTarget: 'Customer workability target',
  stoneQuality: 'Stone Quality',
  sandQuality: 'Sand Quality',
  targetWC: 'Target W/C ratio from recipe',
  controlClass: 'Control Class',
  chlorideClass: 'Chloride Class',
  typeAndStrength1: 'Type of cement and strengthclass 1',
  typeAndStrength2: 'Type of cement and strengthclass 2',
  typeOfAddition: 'Type of addition',
  typeOfAdmixture: 'Type of admixture',
  orderedTemp: 'Ordered Temperature',
  fibers1: 'Type and content of fibers 1',
  fibers2: 'Type and content of fibers 2',
  calculatedMaturityTime: 'Calculated maturity time from W/C contact time',

  mixIdentity: 'Mix Identity',
  grade: 'Grade',
  mixType: 'Mix Type',
  maxAggSize: 'Max Agg Size',
  maxAggType: 'Max Agg Type',
  cementType: 'Cement Type',
  admixtures: 'Admixtures',
  addmixDescription: 'Addmix Description',
  slump: 'Slump',
  minCementContent: 'Min Cement Content',
  maxWatCemRatio: 'Max Water/Cement Ratio',
  maxWaterToAdd: 'Max Water To Add',
  mortarMinWorkingLife: 'Mortar Min Working Life',
  code: 'Code',
  dcClass: 'dcClass',
  chloride: 'Chloride',
  signedByHanson: 'Signed By Hanson',
  warmStamp: 'Warm Stamp',
  coldStamp: 'Cold Stamp',
  containsSikatard: 'Contains Sikatard',
  containsDelvo: 'Contains Delvo',

  generalDelivery: 'Allgemeine Lieferinformationen',
  materialInfo: 'Materialinformationen',

  water: 'Wasser',
  materialService: 'Materialien / Service',
  returnedMaterial: 'Zurückgegebenes Material',

  waterAdded: 'Wasser hinzugefügt',
  concreteOnTruck: 'Beton auf LKW',
  concreteOnTruckUom: 'Standardmaßeinheit für Beton auf LKW',
  reason: 'Grund',
  customerAddedWater: 'Kunde hat Wasser hinzugefügt',
  addWater: 'Wasser hinzufügen',
  addedAt: 'Hinzugefügt am',

  addMaterialService: 'Material/Dienstleistung hinzufügen',
  customerAddedProduct: 'Vom Kunden hinzugefügtes Produkt',

  addReturnedMaterial: 'Retourniertes Material hinzufügen',
  leftover: 'Rest',

  submitAcceptance: 'Annahme übermitteln',
  customerAcceptance: 'Kundenakzeptanz',
  finalize: 'Beenden',

  materialSummary: 'Materialzusammenfassung',
  mix: 'Mischen',
  ordered: 'Bestellt',
  loaded: 'Lieferlast',
  totalOrdered: 'Gesamtbestellmenge',
  loadedOrderQuantity: 'Geladene Bestellmenge',
  loadedOrder: 'Geladene Bestellung',

  driverComment: 'Fahrerkommentare',
  customerComment: 'Kundenkommentar',
  editComment: 'Kommentar bearbeiten',
  addComment: 'Einen Kommentar hinzufügen',
  additionalInfo: 'Zusätzliche Information',
  onBoard: 'Am Bord',
  driver: 'Treiber',
  commentInstruction: 'Kommentaranweisung',
  signatureInstruction: 'Unterschrift des Kunden',
  customerSignature: 'Unterschrift des Kunden',

  general: 'Allgemein',

  status: 'Status',
  updateStatusTime: 'Statuszeit aktualisieren',
  delete: 'Löschen',
  cancel: 'Absagen',
  back: 'Der Rücken',
  add: 'Hinzufügen',
  ok: 'OK',
  lastRefresh: 'Letzte Aktualisierung',
  refreshEnabled: 'Aktualisieren aktivieren',

  id: 'ID',
  name: 'Name',
  invoiceable: 'Rechnungspflichtig',
  provideSignature: 'Unterschrift bereitstellen',
  ticket: 'Fahrkarte',
  details: 'Einzelheiten',
  qrCode: 'QR',

  driverAcceptanceReason: 'Grund',
  driverAcceptance: 'Fahrerakzeptanz',

  'payment-method': 'Zahlungsarten',
  'add-payment-method': 'Zahlungsmethode hinzufügen',

  'reason-code': 'Grundcode',
  'add-reason-code': 'Ursachencode hinzufügen',

  'qr-code': 'QR Code',
  'unloading-method': 'Entlademethoden',
  'add-unloading-method': 'Entlademethode hinzufügen',

  'driver-comment': 'Fahrerkommentare',
  'add-driver-comment': 'Fahrerkommentar hinzufügen',
  'driver-sellable': 'Treiber Verkaufsartikel',

  vehicle: 'Fahrzeuge',
  'add-vehicle': 'Fahrzeug hinzufügen',

  'vehicle-type': 'Fahrzeugtypen',
  vehicleTypes: 'Fahrzeugtypen',
  vehicleType: 'Fahrzeugtyp',
  'add-vehicle-type': 'Fahrzeugtyp hinzufügen',
  countries: 'Länder',
  country: 'Land',

  type: 'Art',
  uom: 'Maßeinheit',
  mandatory: 'Obligatorisch',

  qrTemplate: 'QR-Code-Vorlage',
  includeMixInfo: 'Fügen Sie Mix-Informationen in den QR-Code ein',
  includeTicketEvents: 'Fügen Sie Ticket-Events in den QR-Code ein',

  quantityField: 'Mengenfeld aktivieren',
  concreteOnTruckField: 'Beton auf LKW-Feld aktivieren',
  timeField: 'Zeitfeld aktivieren',
  reasonField: 'Grundfeld aktivieren',

  driverInstructions: 'Fahreranweisungen',

  waitingForTicket: 'Waiting for ticket...',

  exceedsLoadedQuantity: 'Der Wert darf die geladene Menge von nicht überschreiten {loadedQuantity}.',
  maxQuantity: 'Maximale Menge',
  maxQuantityError: 'Die Menge darf die definierte Höchstmenge von {maxQty} nicht überschreiten',
  maxValueError: 'Value cannot exceed defined maximum of {maxQty}',
  minValueError: 'Value cannot be less than defined minimum of {minQty}',

  notAuthorized: 'Not Authorized',

  enterTruckNumber: 'Unterschrift des Kunden',
  setTruckNumber: 'LKW-Nummer stellen',

  commentList: 'Kommentarliste',
  list: 'Aufführen',

  gridPreferences: 'Rastereinstellungen',

  loadingUnloadingPoints: 'Laden/Entladen von Standorten',

  activity: 'Aktivität',

  crn: 'CRN',
  update: 'Aktualisieren',

  CONCRETE: 'Beton',
  WATER: 'Wasser',
  ADDITIVE: 'Zusatzstoff',

  'submit-signature-button': 'Annehmen',
  'cancel-signature-button': 'Absagen',

  uomCode: 'Default UOM',
  'product-type': 'Produkttypen',
  'add-product-type': 'Produkttyp hinzufügen',
  'add-product': 'Produkt hinzufügen',
  'setup_driver-comment': 'Fahrerkommentare',
  setup_product: 'Produkte',
  'setup_product-type': 'Produkttypen',
  'setup_qr-code': 'QR Code',
  'setup_reason-code': 'Ursachencodes',
  'setup_unloading-method': 'Entlademethoden',
  'setup_entity-mt': 'Unternehmen',
  setup_vehicle: 'Fahrzeuge',
  'setup_vehicle-type': 'Fahrzeugtypen',
  'setup_data-retentions': 'Data Retention',
  'add-data-retentions': 'Add Data Retention',
  'data-retentions': 'Data Retention',
  setup_carriers: 'Carriers',
  'add-carriers': 'Add Carriers',
  carriers: 'Carriers',
  'setup_concrete-classes': 'Material Compliance',
  'add-concrete-classes': 'Add Material Compliance',
  'concrete-classes': 'Material Compliance',

  companyFleet: 'Company Fleet',

  category: 'Category',
  interval: 'Interval',
  orders: 'Orders And Tickets',
  '30days': '30 Days',
  '60days': '60 Days',
  '90days': '90 Days',
  '1year': '1 Year',
  '3years': '3 Years',

  waterMandatory: 'Water Mandatory',

  qrCodeTemplate: 'QR Code Template',

  waterConfig: 'Optionale Felder',

  waterProduct: 'Wasser',
  waterProductType: 'Wasserprodukttyp',
  defaultWaterProduct: 'Standard-Wasserprodukt',

  invalidTemplate: 'Ungültige Vorlage',

  siteAddress: 'Adresse der Baustelle',
  siteContactName: 'Kontaktname der Baustelle',
  siteContactPhoneNumber: 'Kontakttelefonnummer für die Baustelle',

  'clear-signature-button': 'Clear Signature',

  customerCommentEnabled: 'Kundenkommentar aktivieren',
  driverCommentEnabled: 'Fahrerkommentar aktivieren',

  driverCommentMaxCharacters: 'Maximale Länge',
  customerCommentMaxCharacters: 'Maximale Länge',

  timeZone: 'Zeitzone',

  driverDidNotAdd: 'Treiber wurde nicht hinzugefügt',
  maxLineItems: 'Max Anzahl von Werbebuchungen',
  oneLineItem: 'Es muss mindestens 1 Werbebuchung vorhanden sein',

  signatureSiteContact: 'Kundenname',
  ticketEvents: 'Ticket-Veranstaltungen',
  hideEvent: 'Ereignis ausblenden',
  editable: 'Bearbeitbar',

  ticketDetail: `Fahrkarte #{value}`,
  statusTimes: 'Statuszeiten',

  materialAndDeliveryInfo: 'Material- und Lieferinformationen',

  customerRejection: 'Kundenablehnung',
  'submit-rejection-button': 'Absage übermitteln',
  accept: 'Akzeptieren',
  reject: 'Ablehnen',

  signature: 'Unterschrift',
  'no-signature': 'Keine Unterschrift',

  unFinalizePassword: 'Unfinalisieren Passwort',

  waterInWorkflow: 'Aktivieren Sie den Wasserschritt im Workflow',
  multicountry: 'Multicountry',

  timestampAfterSequence: 'WARNUNG: Die Zeit sollte nicht vor dem vorherigen Zeitstempel liegen',
  timestampBeforeSequence: 'WARNUNG: Die Zeit sollte nicht nach dem nächsten Zeitstempel liegen',

  noRestQuantity: 'No Rest Quantity',

  returnedMaterialMandatory: 'Must Interact With Returned Material Step',

  driverCommentMaxChars: 'Length cannot exceed max of {maxLength} characters.',

  QrCodeEnabled: 'Enable QR Code',

  returnedMaterialInWorkflow: 'Enable Returned Material Step in Workflow',

  materialServiceInWorkflow: 'Enable Material/Service Step in Workflow',

  totalPumpedQuantity: 'Total Pumped Quantity',

  concrete: 'Concrete',

  materialComplianceEnabled: 'Enable Material Compliance',

  pumpStatusTimes: 'Pump Status Times',

  pollingInterval: 'Polling Interval (seconds)',

  tenSecs: 'Interval must be at least 10 seconds',

  materialCompliance: 'Material Compliance',
  consistenceValue: 'Consistence',
  consistenceIsCompliant: 'Consistence complies with BS8500-2',
  airContentValue: 'Air content',
  airContentIsCompliant: 'Air Content complies with BS8500-2',

  consistenceIsCompliantShort: 'Consistence complies',
  airContentIsCompliantShort: 'Air Content complies',
  compliance: 'Material Compliance with BS8500-2',

  family_name: 'Last Name',
  given_name: 'First Name',
  email: 'Email',
  role: 'Role',
  inviteUser: 'Invite User',
  carrier: 'Carrier',
  emailOrPhoneNumber: 'Email',
  usersEditor: 'User Editor',
  verifyPassword: 'Verify Password',
  verificationCode: 'Verification Code',
  register: 'Register',
  registerInstructions: 'Please fill out this form to finalize your registration.',
  'password-requirements':
    'Your password must be at least 8 characters, contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character.',
  passwordMinCharacters: 'Password must contain 8 or more characters.',
  passwordMaxCharacters: 'Password cannot contain more than 99 characters.',
  passwordLowerCase: 'Password must include 1 or more lower case letter.',
  passwordUpperCase: 'Password must include 1 or more upper case letter.',
  passwordNumber: 'Password must include 1 or more numeric character.',
  passwordSpecialCharacter: 'Password must include 1 or more special character.',
  passwordsDontMatch: "Passwords don't match!",
  success: 'Success!',
  'registration-success': 'Your registration was successful.',
  'post-registration-instructions': "You've completed the registration process. You may now log in.",
  createDate: 'Create Date',
  customerPo: 'Customer PO Number',
  specification: 'Specification',

  description: 'Description',
  classType: 'Class Type',
  minimumValue: 'Minimum Value',
  maximumValue: 'Maximum Value',

  TrackIt: 'TrackIt',
  trackIt3PContext: 'TrackIt 3P Context',
  inviteLanguage: 'Invite Language',
  truckNumber: 'Truck Number',
  adverseTemperature:
    'Ambient temperature at the time of mixing was such that we cannot guarantee that the concrete will be in the temperature ranges of 5-35oC as specified in EN 206.',
  environmentalNote:
    'This load contains an environmental agent which does not exceed 0.03% by weight of the concrete. Water containing this material for the manufacture of concrete will comply with BS EN 1008.',

  unloadingMethodEnabled: 'Enable Unloading Method',

  errors,
  themes,
};
