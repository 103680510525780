import { Form, Responsive } from 'connex-cds';
import React from 'react';
import { HeaderMobile } from './HeaderMobile';
import { HeaderDesktop } from './HeaderDesktop';

export const Header = ({ flow, signed }) => {
  const { values } = Form.useFormContext();

  const submitAcceptanceVisible = React.useMemo(
    () => values?.finalized && values?.signature?.image,

    [values?.finalized, values?.signature?.image]
  );

  return (
    <Responsive>
      <HeaderMobile flow={flow} submitAcceptanceVisible={submitAcceptanceVisible} signed={signed} />
      <HeaderDesktop flow={flow} submitAcceptanceVisible={submitAcceptanceVisible} />
    </Responsive>
  );
};
