import { Form, Localization, Responsive } from 'connex-cds';
import { useTicketContext } from '../../../TicketContext';
import React from 'react';
import { ReturnedMaterialsMobile } from './ReturnedMaterialsMobile';
import { ReturnedMaterialsDesktop } from './ReturnedMaterialsDesktop';
import { find } from 'lodash';
import { useCompanySetup, useReasonCodeSetup, useVehicleSetup } from '../../../MasterDataProvider';

const { Uom } = Localization;

export const ReturnedMaterials = () => {
  const { ticket } = useTicketContext();

  const { values, setFieldValue } = Form.useFormContext();

  const [concreteOnTruckUom, setConcreteOnTruckUom] = React.useState();

  const primaryLineItem = React.useMemo(() => find(ticket?.lineItems, { isPrimary: true }), [ticket?.lineItems]);

  const mixInfo = React.useMemo(() => {
    return {
      mix: `${primaryLineItem?.item?.id} / ${primaryLineItem?.item?.description}`,
      loadedQty: <Uom uom={primaryLineItem?.quantity} />,
    };
  }, [primaryLineItem]);

  const vehicleSetup = useVehicleSetup();
  const companySetup = useCompanySetup();
  const reasonCodeSetup = useReasonCodeSetup();

  const vehicleType = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return targetVehicle?.vehicleTypes;
  }, [vehicleSetup.data, ticket?.vehicleId]);

  const options = React.useMemo(
    () =>
      reasonCodeSetup.data
        ?.filter?.(d => d.type === 'RETURNED')
        ?.filter?.(reason => reason.status === 'ACTIVE')
        ?.filter?.(reason =>
          companySetup?.data?.isMulticountry === true
            ? reason?.countries?.includes(ticket?.origin?.address?.countryCode) || !reason?.countries?.length
            : reason
        )
        ?.filter?.(reason => reason?.vehicleTypes?.includes(vehicleType) || !reason?.vehicleTypes?.length) || [],
    [companySetup?.data?.isMulticountry, reasonCodeSetup.data, ticket?.origin?.address?.countryCode, vehicleType]
  );

  React.useEffect(() => {
    const defaultConcreteOnTruckUomId = companySetup.data?.concreteOnTruckUom;
    setConcreteOnTruckUom(defaultConcreteOnTruckUomId);
  }, [companySetup.data]);

  React.useEffect(() => {
    if (values?.driverDidNotAdd) {
      setFieldValue('returnConcreteOnTruck', undefined);
      setFieldValue('returnReason', undefined);
    }
  }, [setFieldValue, values?.driverDidNotAdd]);

  const disabled = React.useMemo(() => {
    return values?.driverDidNotAdd;
  }, [values?.driverDidNotAdd]);
  return (
    <Responsive>
      <ReturnedMaterialsMobile
        mixInfo={mixInfo}
        concreteOnTruckUom={concreteOnTruckUom}
        disabled={disabled}
        options={options}
        reasonCodeSetup={reasonCodeSetup}
      />
      <ReturnedMaterialsDesktop
        mixInfo={mixInfo}
        concreteOnTruckUom={concreteOnTruckUom}
        disabled={disabled}
        options={options}
        reasonCodeSetup={reasonCodeSetup}
      />
    </Responsive>
  );
};
