import cn from 'classnames';
import { Drawer, Form, Layout } from 'connex-cds';
import { find, omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useCompanySetup, useProductSetup, useReasonCodeSetup, useVehicleSetup } from '../../../MasterDataProvider';
import { useTicketContext } from '../../../TicketContext';
import style from './form-style';

const Styled = styled.div`
  ${style}
`;

const { Row, Container } = Layout;

export const FormSection = ({ className }) => {
  const {
    Components: {
      Reason,
      Quantity,
      ConcreteOnTruck,
      Time,
      WaterProduct,
      CancelButton,
      SaveButton,
      ResetButton,
      DriverDidNotAdd,
    },
    values,
    setFieldValue,
    mode,
  } = Form.useFormContext();

  const { closeDrawer } = Drawer.useDrawerContext();
  const [productUom, setProductUom] = React.useState();
  const [concreteOnTruckUom, setConcreteOnTruckUom] = React.useState();

  React.useEffect(() => {
    if (!values?.waterProduct || values?.waterProduct?.mobileTicket?.isReasonFieldEnabled === false) {
      setFieldValue('reason', undefined);
    }
    if (!values?.waterProduct || values?.waterProduct?.mobileTicket?.isConcreteOnTruckFieldEnabled === false) {
      setFieldValue('concreteOnTruck', undefined);
    }
  }, [setFieldValue, values?.waterProduct]);

  const { ticket } = useTicketContext();

  const companySetup = useCompanySetup();
  const vehicleSetup = useVehicleSetup();
  const reasonCodeSetup = useReasonCodeSetup();
  const productSetup = useProductSetup();

  const busy = React.useMemo(() => {
    return companySetup.isLoading || vehicleSetup.isLoading || reasonCodeSetup.isLoading || productSetup.isLoading;
  }, [companySetup.isLoading, productSetup.isLoading, reasonCodeSetup.isLoading, vehicleSetup.isLoading]);

  React.useEffect(() => {
    setProductUom(values?.waterProduct?.uomCode);
  }, [values?.waterProduct]);

  const vehicleType = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return targetVehicle?.vehicleTypes || [];
  }, [ticket?.vehicleId, vehicleSetup.data]);

  const reasonsOptions = React.useMemo(
    () =>
      reasonCodeSetup?.data
        ?.filter?.(d => d.type === 'WATER')
        ?.filter?.(reason => reason.status === 'ACTIVE')
        ?.filter?.(reason =>
          companySetup?.data?.isMulticountry === true
            ? reason?.countries?.includes(ticket?.origin?.address?.countryCode) || !reason?.countries?.length
            : reason
        )
        ?.filter?.(reason => reason?.vehicleTypes?.includes(vehicleType) || !reason?.vehicleTypes?.length) || [],
    [companySetup?.data?.isMulticountry, reasonCodeSetup?.data, ticket?.origin?.address?.countryCode, vehicleType]
  );

  const waterOptions = React.useMemo(() => {
    const waterProductType = companySetup?.data?.waterProductType?.id;
    if (waterProductType) {
      return (
        productSetup?.data
          ?.filter?.(d => d.type === waterProductType)
          ?.filter?.(product => product.status === 'ACTIVE')
          ?.filter?.(product =>
            companySetup?.data?.isMulticountry === true
              ? product?.countries?.includes(ticket?.origin?.address?.countryCode) || !product?.countries?.length
              : product
          )
          ?.filter?.(product => product?.vehicleTypes?.includes(vehicleType) || !product?.vehicleTypes?.length) || []
      );
    }
    return [];
  }, [
    companySetup?.data?.isMulticountry,
    companySetup?.data?.waterProductType?.id,
    productSetup?.data,
    ticket?.origin?.address?.countryCode,
    vehicleType,
  ]);

  React.useEffect(() => {
    const defaultWaterRef = companySetup?.data?.defaultWaterProduct?.productRef;
    const defaultWater = find(productSetup?.data, { crn: defaultWaterRef });
    if (!values?.waterProduct?.productRef && defaultWater) {
      setFieldValue('waterProduct', { ...omit(defaultWater, 'crn'), productRef: defaultWater.crn });
    }
  }, [
    companySetup?.data?.defaultWaterProduct?.productRef,
    productSetup?.data,
    setFieldValue,
    values?.waterProduct,
    waterOptions,
  ]);

  React.useEffect(() => {
    const defaultConcreteOnTruckUomId = companySetup?.data?.concreteOnTruckUom;
    setConcreteOnTruckUom(defaultConcreteOnTruckUomId);
  }, [companySetup?.data?.concreteOnTruckUom]);

  const mobileTicketConfig = React.useMemo(() => {
    const targetItem = find(productSetup?.data, { crn: values?.waterProduct?.productRef });
    return targetItem?.mobileTicket;
  }, [productSetup?.data, values?.waterProduct?.productRef]);

  const disabled = React.useMemo(() => {
    return values?.driverDidNotAdd;
  }, [values?.driverDidNotAdd]);

  const productDisabled = React.useMemo(() => {
    return values?.suggestedToDriver || values?.driverDidNotAdd;
  }, [values?.driverDidNotAdd, values?.suggestedToDriver]);

  React.useEffect(() => {
    if (values?.driverDidNotAdd) {
      setFieldValue('quantity', undefined);
      setFieldValue('reason', undefined);
      setFieldValue('concreteOnTruck', undefined);
    }
  }, [setFieldValue, ticket?.lineItems, values?.driverDidNotAdd, values?.item?.productRef]);

  return (
    <Styled className={cn('form-section add-water-form-section', className)}>
      <Container flex={1}>
        <div className="form">
          <div className="row-1">
            <WaterProduct options={waterOptions} showSearch={false} disabled={productDisabled} />
            {mobileTicketConfig?.isReasonFieldEnabled && (
              <Reason options={reasonsOptions} busy={busy} showSearch={false} disabled={disabled} />
            )}
          </div>
          <div className="row-2">
            <div className="left">
              <Quantity uomCode={productUom} disabled={disabled} />
              {mobileTicketConfig?.isConcreteOnTruckFieldEnabled && (
                <ConcreteOnTruck uomCode={concreteOnTruckUom} disabled={disabled} />
              )}
            </div>
            {mobileTicketConfig?.isTimeFieldEnabled && <Time />}
          </div>
          {mode === 'edit' && (
            <div className="row-3">
              <DriverDidNotAdd />
            </div>
          )}
        </div>
        <div className="actions">
          <div className="left">
            <ResetButton />
          </div>
          <div className="right">
            <Row>
              <CancelButton onCancel={closeDrawer} />
              <SaveButton onDone={closeDrawer} />
            </Row>
          </div>
        </div>
      </Container>
    </Styled>
  );
};
