import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import { Drawer, Form, Layout, Responsive } from 'connex-cds';
import { CommentsMobile } from './CommentsMobile';
import { CommentsDesktop } from './CommentsDesktop';
import { useTicketContext } from '../../../../TicketContext';
import { useCompanySetup } from '../../../../MasterDataProvider';
import { CommentEditor } from './CommentEditor';
import { useViewport } from '../../../../../../../../util/useViewport';

const Styled = styled(Layout.Column)`
  ${style}
`;

export const Comments = () => {
  const { values } = Form.useFormContext();
  const { finalized } = useTicketContext();
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();

  const dimensions = useViewport();

  const companySetup = useCompanySetup();

  const handleCommentClick = React.useCallback(() => {
    if (!finalized || values?.customerStatus === 'ACCEPTED' || values?.customerStatus === 'REJECTED') return;

    openDrawer({
      titleStringId: 'customerSignature',
      component: <CommentEditor onFinally={closeDrawer} />,
      width: Math.min(750, dimensions?.width),
    });
  }, [closeDrawer, dimensions?.width, finalized, openDrawer, values?.customerStatus]);

  return (
    <Styled className={cn('comments')}>
      <Responsive>
        <CommentsMobile companySetup={companySetup} handleCommentClick={handleCommentClick} />
        <CommentsDesktop companySetup={companySetup} handleCommentClick={handleCommentClick} />
      </Responsive>
    </Styled>
  );
};
