import { Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useCompanySetup, useVehicleSetup, useVehicleTypeSetup } from '../../../MasterDataProvider';
import Comments from '../../../ticket-details/comments/Comments';
import DriverInstructions from '../../../ticket-details/driver-instructions/DriverInstructions';
import { LoadingUnloadingPoints } from '../../../ticket-details/logistical-info/loading-unloading-points/LoadingUnloadingPoints';
import { MaterialSummary } from '../../../ticket-details/logistical-info/material-summary/MaterialSummary';
import StatusTimes from '../../../ticket-details/logistical-info/StatusTimes';
import { useTicketContext } from '../../../TicketContext';
import UnloadingMethodForm from '../../../UnloadingMethodForm';
import { find } from 'lodash';

const { Column, Row, Container } = Layout;

const Styled = styled.div``;

export const TicketDetailDesktop = props => {
  const companySetup = useCompanySetup();
  const { ticket } = useTicketContext();
  const { values } = Form.useFormContext();

  const vehicleSetup = useVehicleSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  const vehicleTypeData = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return find(vehicleTypeSetup.data, { id: targetVehicle?.vehicleTypes });
  }, [vehicleSetup.data, vehicleTypeSetup.data, ticket?.vehicleId]);

  return (
    <Styled className={cn('ticket-detail-desktop')}>
      <Column>
        <Row flex={1} className="content-row">
          <Column flex={1} className="">
            <MaterialSummary />
            <LoadingUnloadingPoints data={values} />
            {companySetup?.data?.isDriverCommentEnabled && (
              <Container style={{ padding: '15px' }}>
                <Comments />
              </Container>
            )}
            {ticket?.customData?.ticketType !== 'PMP' &&
              companySetup?.data?.isUnloadingMethodEnabled &&
              vehicleTypeData?.isUnloadingMethodEnabled && (
                <Container style={{ padding: '10px', paddingBottom: '5px' }}>
                  <UnloadingMethodForm />
                </Container>
              )}
          </Column>
          <Column flex={1}>
            <StatusTimes />
            <Container style={{ padding: '10px', flex: '1' }}>
              <DriverInstructions data={values} />
            </Container>
          </Column>
        </Row>
      </Column>
    </Styled>
  );
};
