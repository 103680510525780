import cn from 'classnames';
import { Drawer, Form, Layout, Responsive } from 'connex-cds';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDataStore } from '../../../datastore/DataStore';
import style from '../../../style';
import { useTicketContext } from '../../../TicketContext';
import { TicketDetailDesktop } from './TicketDetailDesktop';
import { TicketDetailMobile } from './TicketDetailMobile';

const { Column, Row, Container } = Layout;

const Styled = styled(Column)`
  ${style}
`;

export const TicketDetail = () => {
  const { validateForm, resetForm } = Form.useFormContext();
  const { ticket } = useTicketContext();

  React.useEffect(() => {
    validateForm();
  }, [validateForm]);

  React.useEffect(() => {
    if (ticket === null) {
      resetForm({ reinitialize: true });
    }
  }, [resetForm, ticket]);

  return (
    <Responsive>
      <TicketDetailMobile />
      <TicketDetailDesktop />
    </Responsive>
  );
};
