import { DateTime } from 'luxon';
import { toUtcIso } from 'connex-cds';
export const getMockTicket = ({ entityRef, truckNumber, ticketNumber, isPump = false }) => ({
  dispatchDateTime: toUtcIso(DateTime.now()),
  id: ticketNumber,
  ticketEvents: {
    END_UNLOADING: {
      longitude: -96.4474,
      latitude: 32.6738,
      eventDateTime: '2022-11-28T15:31:36Z',
    },
    TO_JOB: {
      longitude: -96.340256,
      latitude: 32.901936,
      eventDateTime: '2022-11-28T14:23:35Z',
    },
    PRINTED: {
      longitude: -96.47047,
      latitude: 32.74904,
      eventDateTime: '2022-11-28T14:11:31.292Z',
    },
    LOADING_STARTED: {
      longitude: -96.47047,
      latitude: 32.74904,
      eventDateTime: '2022-11-28T14:15:31.292Z',
    },
    LOADING_COMPLETE: {
      longitude: -96.47047,
      latitude: 32.74904,
      eventDateTime: '2022-11-28T14:20:31.292Z',
    },
    UNLOADING: {
      longitude: -96.44858,
      latitude: 32.672276,
      eventDateTime: '2022-11-28T15:13:06Z',
    },
    ARRIVE_JOB: {
      longitude: -96.44875,
      latitude: 32.672173,
      eventDateTime: '2022-11-28T14:56:36Z',
    },
  },
  scheduledEvents: {
    END_UNLOADING: '2022-07-28T15:31:36Z',
    LOADING_STARTED: '2022-07-28T15:31:36Z',
    IN_YARD: '2022-07-28T16:38:22Z',
    TO_JOB: '2022-07-28T14:23:35Z',
    LEAVE_JOB: '2022-07-28T15:51:36Z',
    CREATED: '2022-07-28T14:11:31.292Z',
    UNLOADING: '2022-07-28T15:13:06Z',
    ARRIVE_JOB: '2022-07-28T14:56:36Z',
  },
  containers: [
    {
      netWeight: {
        value: 5,
        uomCode: 'M3',
      },
      item: {
        description: 'C30T055',
        id: 'C30T055',
      },
      tareWeight: {
        value: 100,
        uomCode: 'M3',
      },
      grossWeight: {
        value: 100,
        uomCode: 'M3',
      },
      vehicle: {
        vehicleType: {
          description: 'Default Truck',
          id: 'Default Truck',
        },
        originatorRecordId: truckNumber,
        id: truckNumber,
        description: truckNumber,
      },
    },
  ],
  supplierSalesOrder: {
    name: 'avenue général Dumonceau',
    id: 'avenue général Dumon',
  },
  destination: {
    address: {
      postalCode: '1190',
      countryCode: 'BEN',
      city: 'Forest',
      coordinates: {},
      address1: 'Av. Général Dumonceau 34',
      countrySubDivision: ' ',
    },
    id: 'avenue général Dumon',
    description: 'avenue général Dumonceau',
    contacts: [
      {
        phone: '+32493850049',
        contactRole: 'general',
        name: 'Client',
      },
    ],
    originatorRecordId: 'avenue général Dumon',
    instructions: 'Driver should talk to Jon upon arrival.\n10 EA ServText1\n10 LTR ServText2',
  },
  entityRef,
  supplierStatus: {
    statusCode: 'CREATED',
  },
  carrierParty: {
    originatorRecordId: 'Default Carrier',
    id: 'Default Carrier',
    contacts: [],
    name: 'Default Carrier',
    entityRef: 'de0e48b3-24ba-5a7f-ad30-5ed403d101cd',
  },
  active: true,
  isVoided: false,
  lookupKey: '67676767',
  dispatchOrder: {
    dispatchOrderRef: '6b0bc960-3ed2-5a46-9273-a3e844921bf6',
    id: '9',
    originatorRecordId: '95938',
    totalOrdered: {
      value: 60,
      uomCode: 'MTQ',
    },
    totalTicketed: {
      reasonCode: undefined,
      value: 30,
      uomCode: 'MTQ',
    },
    deliverySchedule: {
      startDateTime: '2022-07-20T14:13:16Z',
      finishDateTime: '2022-07-20T16:36:16Z',
      deliverySpacing: {
        value: 96,
        uomCode: 'MIN',
      },
    },
  },
  paymentMethod: 'UNKNOWN',
  customerStatus: {
    statusCode: 'PENDING',
  },
  unloadingMethod: {
    id: '4434',
    unloadingMethodRef: '5fa610ef-f61e-460f-95ab-04094aaade6d',
    description: 'Wheelbarrow',
  },
  createdBy: {
    name: 'HC',
    id: 'HC',
  },
  originatorSystemId: 'HC',
  customerParty: {
    job: 'avenue général Dumonceau',
    originatorRecordId: 'ba44c07a-fcac-513d-bbcb-1592860124b8',
    id: 'IMAION',
    name: 'IMAION',
    entityRef: 'ba44c07a-fcac-513d-bbcb-1592860124b8',
  },
  originatorSystemType: 'HC',
  deliveryOption: 'DELIVERY',
  typeId: 'ticket',
  originatorModifiedDateTime: '2022-08-10T16:51:35.824Z',
  customData: {
    ticketType: isPump ? 'PMP' : 'DLV',
    manualLoadTrigger: false,
    runSheetNumber: '202207201613161490010469465',
    multidrop: undefined,
    statusCode: undefined,
    referenceNumber1: undefined,
    schedule: {
      pickupTimeFrom: '2022-07-20T14:13:16Z',
      pickupTimeTo: '2022-07-20T14:40:16Z',
      deliveryTimeFrom: '2022-07-20T15:19:16Z',
      deliveryTimeTo: '2022-07-20T16:36:16Z',
      agreeWindowFrom: '2022-07-20T10:55:00Z',
      agreeWindowTo: '2022-07-20T11:00:00Z',
      pumpArriveFrom: undefined,
      pumpArriveTo: '2023-05-17T17:18:10Z',
    },
    orderDetails: {
      orderPlaceBy: 'OrderPlacedBy',
      orderPlaceByPhone: 'OrderPlacedByPhone',
      poItem: 'POitem',
      deliveryFlow: '1.111',
      unloadingTime: '91',
      customerId: 'CustomerId',
      customerPoNumber: 'CustomerPONumber',
    },
    productDetails: {
      matSalesText: 'MatSalesText',
      strengthClass: 'StrengthClass',
      strengthClass1: 'StrengthClass1',
      strengthClass2: 'StrengthClass2',
      environmentExposure: 'EnvironmentExposure',
      metExposureClass: 'MetExposureClass',
      dmax: 'DMAX',
      certificationText: 'CertificationText',
      customerSlumpTarget: 'CustomerSlumpTarget',
      stoneQuality: 'StoneQuality',
      sandQuality: 'SandQuality',
      targetWcRatioRecipe: 'TargetWCRatioRecipe',
      controlClass: 'ControlClass',
      chlorideClass: 'ChlorideClass',
      additionType: 'AdditionType',
      admixtureType: 'AdmixtureType',
      orderedTemperature: 'OrderedTemperature',
      fiber1Type: 'Fiber1Type',
      fiber2Type: 'Fiber2Type',
      wcContactDateTime: '2022-01-01T00:00:00Z',
      maturityColdHot: 'MaturityColdHot',
      specification: 'Specification',
      bccNumber: 'BCCNumber',
      consistencyClass: 'ConsistencyClass',
      vctekvMax: 'VctekvMax',
      grade: 'Grade',
      maxAggSize: 'MaxAggSize',
      maxAggType: 'MaxAggType',
      code: 'Code',
      dcClass: 'DCClass',
      chloride: 100,
      signedByHanson: 'SignedByHanson',
      warmStamp: true,
      coldStamp: false,
      containsSikatard: true,
      containsDelvo: false,
      admixtures: 'Admixtures',
      addmixDescription: 'AddmixDescription',
    },
  },
  sk: {
    vehicleId: truckNumber,
  },
  priceSummary: {
    taxAmount: 0,
    totalPrice: 0,
    subtotalPrice: 0,
    subTotalPrice: 0,
    totalFreightCharge: 0,
  },
  commerceTypeCode: 'SELL',
  originatorCreatedDateTime: '2022-08-10T16:51:35.824Z',
  origin: {
    address: {
      city: 'Brussel',
      coordinates: {
        longitude: 4.35333,
        latitude: 50.866695,
      },
      address1: 'Havenlaan 63 Avenue du port',
      postalCode: '1000 BE',
      countryCode: 'US',
    },
    originatorRecordId: 'BES4',
    id: 'BES4',
    description: 'IB Brussel',
  },
  originatorClientId: '51f55611-3e74-48df-9437-99815d818522',
  dataModelVersion: '2.0.99',
  purchaseOrder: 'PONumber',
  primaryItemType: 'CONCRETE',
  lineItems: [
    {
      item: {
        id: 'C30T055GBS38P2',
        description: 'C30T055GBS38P2',
        originatorRecordId: 'C30T055GBS38P2',
        itemType: 'CONCRETE',
        mix: {
          id: 'MixIdentity',
          type: 'MixType',
        },
      },
      quantity: {
        value: 5,
        uomCode: 'MTQ',
      },
      isPrimary: true,
      production: {
        cementType: 'CementType',
        minCementContent: 'MinCementContent',
        maxWaterToAdd: {
          value: 10,
        },
        mortarMinWorkingLife: 'MortarMinWorkingLife',
      },
      properties: {
        slump: 'Consistence',
      },
      specifiedProperties: {
        maxWatCemRatio: 0.25,
      },
    },
    {
      item: {
        id: 'ServText1',
        description: 'ServText1',
        originatorRecordId: 'ServText1',
        itemType: 'SERVICE',
      },
      quantity: {
        value: 10,
        uomCode: 'EA',
      },
      isPrimary: false,
      requiresDriverReview: true,
    },
    {
      item: {
        id: 'ServText2',
        description: 'NewProduct',
        originatorRecordId: 'ServText2',
        itemType: 'SERVICE',
      },
      quantity: {
        value: 10,
        uomCode: 'TNE',
      },
      isPrimary: false,
      requiresDriverReview: true,
    },
  ],
  originatorRecordId: '1234567890.1234567890_001',
  supplierParty: {
    id: 'HC-1',
    originatorRecordId: '51f55611-3e74-48df-9437-99815d818522',
    name: 'Heidelberg Cement Dev',
    entityRef: '51f55611-3e74-48df-9437-99815d818522',
  },
  order: {
    id: '6767',
    orderRef: '791a98cc-751c-58ac-943e-b3e315b64cdf',
  },
});
