import cn from 'classnames';
import { Form, Localization, Modal } from 'connex-cds';
import { find, isEmpty, omit } from 'lodash';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { cacheItem } from '../../../../../util/cache';
import { useCompanySetup, useVehicleSetup, useVehicleTypeSetup } from '../MasterDataProvider';
import { useTicketContext } from '../TicketContext';
import { UnFinalize } from './modal/UnFinalize';
import steps from './steps';
import style from './style';
import { ResponsiveWizardSteps } from './wizard-steps/ResponsiveWizardSteps';
import { STEP_STATUS } from './wizard-steps/WizardSteps';
import pkg from '../../../../../../package.json';

const Styled = styled.div`
  ${style}
`;

export const Wizard = () => {
  const { stepId } = useParams();
  const navigate = useNavigate();
  const { ticket, finalized, setFinalized } = useTicketContext();
  const { errors, values } = Form.useFormContext();
  const [currentStep, setCurrentStep] = React.useState('ticketDetail');
  const translateMessage = Localization.useTranslateMessage();
  const [stepTouched, setStepTouched] = React.useState({ ticketDetail: true });

  const companySetup = useCompanySetup();
  const vehicleSetup = useVehicleSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  React.useEffect(() => {
    cacheItem(`state`, JSON.stringify({ [ticket.crn]: values }));
  }, [ticket.crn, values]);

  const vehicleTypeData = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return find(vehicleTypeSetup.data, { id: targetVehicle?.vehicleTypes });
  }, [vehicleSetup.data, vehicleTypeSetup.data, ticket?.vehicleId]);

  const translatedStepsStatus = React.useMemo(() => {
    let filteredSteps = steps;
    if (companySetup?.data?.isWaterInWorkflow === false) {
      filteredSteps = steps.filter(step => step.id !== 'water');
    } else if (vehicleTypeData) {
      if (companySetup?.data?.isWaterInWorkflow !== false && vehicleTypeData?.isWaterInWorkflow !== true) {
        filteredSteps = steps.filter(step => step.id !== 'water');
      }
    }
    if (companySetup?.data?.isReturnedMaterialInWorkflow === false || ticket?.customData?.ticketType === 'PMP') {
      filteredSteps = filteredSteps.filter(step => step.id !== 'returnedMaterial');
    } else if (vehicleTypeData) {
      if (
        companySetup?.data?.isReturnedMaterialInWorkflow !== false &&
        vehicleTypeData?.isReturnedMaterialInWorkflow !== true
      ) {
        filteredSteps = filteredSteps.filter(step => step.id !== 'returnedMaterial');
      }
    }
    if (companySetup?.data?.isMaterialServiceInWorkflow === false) {
      filteredSteps = filteredSteps.filter(step => step.id !== 'materialService');
    } else if (vehicleTypeData) {
      if (
        companySetup?.data?.isMaterialServiceInWorkflow !== false &&
        vehicleTypeData?.isMaterialServiceInWorkflow !== true
      ) {
        filteredSteps = filteredSteps.filter(step => step.id !== 'materialService');
      }
    }
    if (ticket?.customData?.ticketType !== 'PMP') {
      filteredSteps = filteredSteps.filter(step => step.id !== 'totalPumpedQuantity');
    }
    if (companySetup?.data?.isMaterialComplianceEnabled === false) {
      filteredSteps = filteredSteps.filter(step => step.id !== 'materialCompliance');
    } else if (vehicleTypeData) {
      if (
        companySetup?.data?.isMaterialComplianceEnabled !== false &&
        vehicleTypeData?.isMaterialComplianceEnabled !== true
      ) {
        filteredSteps = filteredSteps.filter(step => step.id !== 'materialCompliance');
      }
    }
    return filteredSteps.map(step => {
      const paths = [].concat(step.path);

      let status = STEP_STATUS.DEFAULT;
      if (step.id === 'water') {
        if (
          values?.waterAddedEvents.some(item => item.suggestedToDriver) &&
          !stepTouched.water &&
          !!errors.waterAddedEvents
        ) {
          status = STEP_STATUS.MANDATORY;
        }
      } else if (step.id === 'materialService') {
        if (
          values?.lineItems.some(item => item.requiresDriverReview || item.suggestedToDriver) &&
          !stepTouched.materialService &&
          vehicleTypeData?.isMaterialServiceInWorkflow !== false &&
          !!errors.lineItems
        ) {
          status = STEP_STATUS.MANDATORY;
        }
      } else if (step.id === 'returnedMaterial') {
        if (
          companySetup?.data?.isReturnedMaterialMandatory === true &&
          !stepTouched.returnedMaterial &&
          vehicleTypeData?.isReturnedMaterialInWorkflow !== false &&
          ticket?.customData?.ticketType !== 'PMP'
        ) {
          status = STEP_STATUS.MANDATORY;
        }
      }
      if (paths.some(p => !!errors[p]) && stepTouched[step.id]) {
        status = STEP_STATUS.ERROR;
      } else if (stepTouched[step.id]) {
        status = STEP_STATUS.OK;
      }
      return {
        ...step,
        title: translateMessage(step.id, { value: ticket?.id }),

        status,
      };
    });
  }, [
    companySetup?.data?.isMaterialComplianceEnabled,
    companySetup?.data?.isMaterialServiceInWorkflow,
    companySetup?.data?.isReturnedMaterialInWorkflow,
    companySetup?.data?.isReturnedMaterialMandatory,
    companySetup?.data?.isWaterInWorkflow,
    errors,
    stepTouched,
    ticket?.customData?.ticketType,
    ticket?.id,
    translateMessage,
    values?.lineItems,
    values?.waterAddedEvents,
    vehicleTypeData,
  ]);

  const translatedSteps = React.useMemo(() => {
    return translatedStepsStatus.map(step => {
      let disabled = false;
      if (step.id === 'water' || step.id === 'materialService') {
        if (
          Object.keys(ticket?.ticketEvents)?.filter(event =>
            ['ARRIVE_JOB', 'READY_TO_PUMP', 'UNLOADING', 'START_PUMPING'].includes(event)
          )?.length === 0
        ) {
          disabled = true;
        }
      } else if (step.id === 'returnedMaterial' || step.id === 'totalPumpedQuantity') {
        if (
          Object.keys(ticket?.ticketEvents)?.filter(event => ['END_UNLOADING', 'PUMPING_COMPLETE'].includes(event))
            ?.length === 0
        ) {
          disabled = true;
        }
      } else if (step.id === 'finalize') {
        if (
          Object.keys(ticket?.ticketEvents)?.filter(event => ['END_UNLOADING', 'PUMPING_COMPLETE'].includes(event))
            ?.length === 0 || companySetup?.data?.isMaterialServiceInWorkflow === false
            ? !isEmpty(omit(errors, 'ticketEvents', 'lineItems'))
            : companySetup?.data?.isMaterialServiceInWorkflow !== false &&
              vehicleTypeData?.isMaterialServiceInWorkflow !== true
            ? !isEmpty(omit(errors, 'ticketEvents', 'lineItems'))
            : !isEmpty(omit(errors, 'ticketEvents')) ||
              translatedStepsStatus?.some(s => s.status === 'mandatory' || s.status === 'error')
        ) {
          disabled = true;
        }
      }
      return {
        ...step,
        disabled,
      };
    });
  }, [errors, ticket?.ticketEvents, translatedStepsStatus]);

  React.useEffect(() => {
    setCurrentStep(finalized ? 'finalize' : stepId);
  }, [finalized, stepId]);

  const handleStepClick = React.useCallback(
    value => {
      setStepTouched(s => ({ ...s, [value]: true }));
      navigate(`../${value}`);
    },
    [navigate]
  );

  const { openModal, closeModal } = Modal.useModalContext();

  const handleBackClick = React.useCallback(() => {
    if (!companySetup?.data?.unFinalizePassword) {
      setFinalized(false);
      return;
    }

    const onOkay = password => {
      if (companySetup?.data?.unFinalizePassword === password) {
        setFinalized(false);
      }
      closeModal();
    };

    openModal({
      title: 'Modal',
      component: <UnFinalize onOkay={onOkay} onCancel={closeModal} />,
    });
  }, [closeModal, companySetup?.data, openModal, setFinalized]);

  const currentComponent = React.useMemo(() => {
    const target = find(steps, { id: currentStep });
    return target?.component;
  }, [currentStep]);

  return (
    <Styled className={cn('wizard', { finalized })}>
      {!finalized ? (
        <div className="header">
          <div className="steps">
            <ResponsiveWizardSteps
              steps={translatedSteps}
              currentStep={currentStep}
              onClick={handleStepClick}
              handleBackClick={handleBackClick}
            />
          </div>
        </div>
      ) : null}
      <div className={cn('step-content')}>
        <div className="step-component">{React.cloneElement(currentComponent, { handleBackClick })}</div>
      </div>
      <div className="env">
        {window?.location?.hostname} v{pkg.version}
      </div>
    </Styled>
  );
};
