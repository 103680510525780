import cn from 'classnames';
import { Core, Form, Localization, toUtcIso, Typography } from 'connex-cds';
import { DateTime } from 'luxon';
import React from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import styled from 'styled-components';
import style from '../signature-comment/style';
import { find } from 'lodash';
import { useTicketContext } from '../TicketContext';

const { Translate } = Localization;

const Styled = styled.div`
  ${style}
`;

export const SignatureCanvas = ({ signature, onChange, ticketId }) => {
  const signaturePadRef = React.useRef();
  const { formatLongDateTime } = Localization.useFormat();
  const { ticket } = useTicketContext();
  const {
    Components: { SignatureSiteContact },
    setFieldValue,
    values,
  } = Form.useFormContext();

  React.useEffect(() => {
    if (signaturePadRef.current) {
      const canvas = signaturePadRef.current.signaturePad.canvas;
      canvas.width = 930;
      canvas.height = 225;
    }
  }, []);

  const handleStrokeEnd = React.useCallback(() => {
    const now = toUtcIso(DateTime.now());

    onChange({
      ...(values?.signature || {}),
      timestamp: now,
      formattedTimestamp: formatLongDateTime(now),
      image: signaturePadRef?.current?.signaturePad?.toDataURL?.(),
      ticketId,
    });
  }, [formatLongDateTime, onChange, ticketId, values?.signature]);

  React.useEffect(() => {
    if (signature?.image) {
      signaturePadRef.current.fromDataURL(signature.image);
    }
  }, [signature, signaturePadRef]);

  React.useEffect(() => {
    const ref = signaturePadRef?.current;
    signaturePadRef?.current?.signaturePad?.addEventListener?.('endStroke', handleStrokeEnd);
    return () => ref?.removeEventListener?.('endStroke', handleStrokeEnd);
  }, [handleStrokeEnd, signaturePadRef]);

  React.useEffect(() => {
    const contact = find(ticket?.destination?.contacts, { contactRole: 'general' });
    if (!values?.signature?.signerName && contact?.name) {
      setFieldValue('signature', { ...values?.signature, signerName: contact.name });
    }
  }, [setFieldValue, ticket?.destination?.contacts]);

  const handleClear = React.useCallback(() => {
    signaturePadRef?.current?.clear?.();
    onChange({
      ...(values?.signature || {}),
      timestamp: undefined,
      formattedTimestamp: undefined,
      image: undefined,
    });
  }, [values?.signature]);

  return (
    <Styled className={cn('signature')}>
      <SignatureSiteContact />
      <div className="instruction">
        <Typography.Subtitle>
          <Translate stringId="signatureInstruction" />
        </Typography.Subtitle>
      </div>
      <div className="signature-outer x">
        <Core.Button onClick={handleClear} stringId="clear-signature-button" data-testid="clear-signature-button" />
        <div className="signature-inner x">
          <SignaturePad redrawOnResize={true} ref={signaturePadRef} width={930} height={225} />
        </div>
      </div>
    </Styled>
  );
};
