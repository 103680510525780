import cn from 'classnames';
import { Localization } from 'connex-cds';
import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import style from '../style';

const { Translate, useTranslateMessage } = Localization;

const Styled = styled.div`
  ${style}
`;
const MaterialInfo = ({ data }) => {
  const translateMessage = useTranslateMessage();

  const primaryLineItem = find(data?.lineItems, { isPrimary: true });

  let mix = '';
  if (data?.lineItems && data?.lineItems.length > 0) {
    data?.lineItems.forEach((lineItem, key) => {
      if (lineItem.isPrimary) {
        mix = lineItem.item.description;
      }
    });
  }

  const context = {
    material: mix,
    salesText: data?.customData?.productDetails?.matSalesText,
    strengthClass: data?.customData?.productDetails?.strengthClass,
    environmentExposure: data?.customData?.productDetails?.environmentExposure,
    metExposureClasses: data?.customData?.productDetails?.metExposureClass,
    dmax: data?.customData?.productDetails?.dmax,
    standardCert: data?.customData?.productDetails?.certificationText,
    specification: data?.customData?.productDetails?.specification,
    workabilityTarget: data?.customData?.productDetails?.customerSlumpTarget,
    stoneQuality: data?.customData?.productDetails?.stoneQuality,
    sandQuality: data?.customData?.productDetails?.sandQuality,
    targetWC: data?.customData?.productDetails?.targetWcRatioRecipe,
    controlClass: data?.customData?.productDetails?.controlClass,
    chlorideClass: data?.customData?.productDetails?.chlorideClass,
    typeAndStrength1: data?.customData?.productDetails?.strengthClass1,
    typeAndStrength2: data?.customData?.productDetails?.strengthClass2,
    typeOfAddition: data?.customData?.productDetails?.additionType,
    typeOfAdmixture: data?.customData?.productDetails?.admixtureType,
    orderedTemp: data?.customData?.productDetails?.orderedTemperature,
    fibers1: data?.customData?.productDetails?.fiber1Type,
    fibers2: data?.customData?.productDetails?.fiber2Type,
    calculatedMaturityTime: data?.customData?.productDetails?.maturityColdHot,

    mixIdentity: primaryLineItem?.item?.mix?.id,
    grade: data?.customData?.productDetails?.grade,
    mixType: primaryLineItem?.item?.mix?.type,
    maxAggSize: data?.customData?.productDetails?.maxAggSize,
    maxAggType: data?.customData?.productDetails?.maxAggType,
    cementType: primaryLineItem?.production?.cementType,
    admixtures: data?.customData?.productDetails?.admixtures,
    addmixDescription: data?.customData?.productDetails?.addmixDescription,
    slump: primaryLineItem?.properties?.slump,
    minCementContent: primaryLineItem?.production?.minCementContent,
    maxWatCemRatio: primaryLineItem?.specifiedProperties?.maxWatCemRatio,
    maxWaterToAdd: primaryLineItem?.production?.maxWaterToAdd?.value,
    mortarMinWorkingLife: primaryLineItem?.production?.mortarMinWorkingLife,
    code: data?.customData?.productDetails?.code,
    dcClass: data?.customData?.productDetails?.dcClass,
    chloride: data?.customData?.productDetails?.chloride,
    signedByHanson: data?.customData?.productDetails?.signedByHanson,
    warmStamp: data?.customData?.productDetails?.warmStamp ? translateMessage(`adverseTemperature`) : 'false',
    coldStamp: data?.customData?.productDetails?.coldStamp ? translateMessage(`adverseTemperature`) : 'false',
    containsSikatard: data?.customData?.productDetails?.containsSikatard
      ? translateMessage(`environmentalNote`)
      : 'false',
    containsDelvo: data?.customData?.productDetails?.containsDelvo ? translateMessage(`environmentalNote`) : 'false',
  };

  return (
    <Styled className={cn('test-form')}>
      {Object.keys(context).map(key => {
        return !!context[key] ? (
          <div className="body" key={key}>
            <Translate stringId={key} data-testid={`${key}Label`} /> :{' '}
            <span data-testid={key}>{`${context[key]}`}</span>
          </div>
        ) : null;
      })}
    </Styled>
  );
};

export default MaterialInfo;
