import { Core, Drawer, Form } from 'connex-cds';
import React from 'react';
import cn from 'classnames';
import { useListRoles } from '../../../../../../query-hooks/roles';
import { useListCarriers } from '../../../../../../query-hooks/carriers';
import { useInviteUser } from '../../../../../../query-hooks/users';
import { find, omit } from 'lodash';

export const View = () => {
  const { Components, values, isValid } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const [busy, setBusy] = React.useState(false);

  const roleQuery = useListRoles();
  const carriersQuery = useListCarriers();
  const inviteUser = useInviteUser();

  const handleClick = React.useCallback(async () => {
    setBusy(true);

    let carriers = [];
    if (values?.carriers?.length) {
      for (let carrier of values?.carriers) {
        const foundCarrier = find(carriersQuery.data, { crn: carrier });
        if (foundCarrier) {
          carriers.push({ name: foundCarrier?.name, carrierRef: foundCarrier?.crn, id: foundCarrier?.id });
        }
      }
    }

    const response = await inviteUser({
      username: values?.username,
      roleRef: values?.role?.roleRef || values?.role?.crn,
      carriers: carriers,
      truckNumber: values?.truckNumber,
      comments: values?.comments,
    });

    setBusy(false);

    if (response?.success === 'ok') {
      closeDrawer();
    }
  }, [carriersQuery.data, closeDrawer, inviteUser, values]);

  const activeCarriers = React.useMemo(() => {
    if (carriersQuery.isSuccess) {
      return carriersQuery?.data?.filter?.(carrier => carrier.status === 'ACTIVE') || [];
    }
    return [];
  }, [carriersQuery?.data, carriersQuery.isSuccess]);

  return (
    <div className={cn('view')}>
      <Components.Username />
      <Components.Role options={roleQuery.data} busy={roleQuery.isLoading} />
      {values?.role?.name?.toLowerCase() === 'driver' && (
        <div>
          <Components.Carriers options={activeCarriers} busy={carriersQuery.isLoading} />
          <Components.TruckNumber />
          <Components.Comments />
        </div>
      )}
      <Components.InviteLanguage />
      <div className="actions">
        <Core.Button
          onClick={handleClick}
          testId="invite-button"
          stringId="inviteUser"
          disabled={!isValid}
          type="primary"
          loading={busy}
        >
          Invite
        </Core.Button>
      </div>
    </div>
  );
};
