import cn from 'classnames';
import { Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';

const { ShortDateTime, Translate } = Localization;
const { Subtitle } = Typography;

const Styled = styled(Layout.Container)``;

const LoadingPointDesktop = ({ className, ticket }) => {
  return (
    <Styled className={cn('loading-point', className)}>
      <Subtitle>
        <Translate stringId="loadingPoint" data-testid="loadingPointLabel" />
      </Subtitle>
      <div>
        <Translate stringId="plantName" data-testid="plantNameLabel" />
        {': '}
        <span data-testid="plantNameValue">{ticket?.origin?.description}</span>
      </div>
      <div>
        <Translate stringId="plantAddress" data-testid="plantAddressLabel" />
        {': '}
        <span data-testid="plantAddressValue">{ticket?.origin?.address.address1}</span>
      </div>
      <div>
        <Translate stringId="loadingScheduleStart" data-testid="loadingScheduleStartLabel" />
        {': '}
        <span style={{ whiteSpace: 'nowrap' }} data-testid="loadingScheduleStart">
          <ShortDateTime date={ticket?.customData?.schedule?.pickupTimeFrom} withDayOfWeek />
        </span>
      </div>
    </Styled>
  );
};

export default LoadingPointDesktop;
