import { Core, Layout, Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { getLocationInfo } from '../../../../../util/getLocationInfo';

const Styled = styled.div`
  width: 100%;
  //text-align: center;
  .wrapper {
    margin-left: auto;
    margin-right: auto;
    min-width: 300px;
    max-width: 600px;
    .container {
      margin: 50px;

      .button-section {
        margin: 30px 0 20px 0;
        display: flex;
        & > * {
          flex: 1;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;

export const Success = () => {
  const locationInfo = React.useMemo(() => getLocationInfo(), []);

  const href = React.useMemo(
    () =>
      `${
        locationInfo?.region === 'localhost'
          ? `http://localhost:3200/login`
          : `https://connex.${locationInfo?.region}.commandalkon.io/login`
      }`,
    [locationInfo?.region]
  );

  const handleClick = React.useCallback(() => {
    window.location.href = href;
  }, [href]);

  return (
    <>
      <Core.Alert severity={Core.SEVERITY.SUCCESS} title="success" message="registration-success" closable showIcon />
      <Styled className={cn('success')}>
        <div className="wrapper">
          <Layout.Container>
            <Localization.Translate
              stringId="post-registration-instructions"
              data-testid="post-registration-instructions"
            />
            <div className="button-section">
              <div>
                <Core.Button stringId="login" data-testid="login-button" onClick={handleClick} type="primary" />
              </div>
            </div>
          </Layout.Container>
        </div>
      </Styled>
    </>
  );
};
