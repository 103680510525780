import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import { useTicketContext } from '../../../TicketContext';
import { Localization } from 'connex-cds';
import { find } from 'lodash';

const { Uom, Translate } = Localization;

const Styled = styled.div`
  ${style}
`;

export const Detail = () => {
  const { ticket } = useTicketContext();

  const mixInfo = React.useMemo(() => {
    const primaryLineItem = find(ticket?.lineItems, { isPrimary: true });

    return {
      mix: primaryLineItem?.item?.id || primaryLineItem?.item?.description,
      loadedQty: <Uom uom={primaryLineItem?.quantity} />,
    };
  }, [ticket?.lineItems]);

  return (
    <Styled className={cn('materialDetail')}>
      <div>
        <Translate stringId="mix" data-testid="mixIdDescriptionLabel" /> :{' '}
        <span data-testid="mixIdDescriptionValue">{mixInfo.mix}</span>
      </div>
      <div>
        <Translate stringId="loaded" data-testid="loadedQtyLabel" /> :{' '}
        <span data-testid="loadedQtyValue">{mixInfo.loadedQty}</span>
      </div>
      {ticket?.dispatchOrder?.totalOrdered?.value >= 0 && (
        <div>
          <Translate stringId="totalOrdered" data-testid="orderedQtyLabel" /> :{' '}
          <span data-testid="orderedQtyValue">
            <Uom uom={ticket?.dispatchOrder?.totalOrdered} />
          </span>
        </div>
      )}
      {ticket?.dispatchOrder?.totalTicketed?.value >= 0 && (
        <div>
          <Translate stringId="loadedOrderQuantity" data-testid="loadedOrderQtyLabel" /> :{' '}
          <span data-testid="loadedOrderQtyValue">
            <Uom uom={ticket?.dispatchOrder?.totalTicketed} />
          </span>
        </div>
      )}
    </Styled>
  );
};
