import cn from 'classnames';
import { Form, Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useTicketContext } from '../../../../TicketContext';
import { CustomerCommentButton } from './CustomerCommentButton';

const { Column, Row, Container } = Layout;
const { Subtitle } = Typography;
const { Translate } = Localization;

const Styled = styled(Column)`
  & > .row {
    & > .column {
      margin-right: 10px;
    }
  }
  [data-testId='driverCommentValue'] {
    textarea {
      height: 70px;
      width: 100%;
      border: 0;
      background: transparent;
    }
  }
`;

export const CommentsDesktop = ({ companySetup, handleCommentClick }) => {
  const { values } = Form.useFormContext();
  const { finalized } = useTicketContext();

  return (
    <Styled className={cn('comments-desktop')}>
      <Row flex={1}>
        {companySetup.data?.isDriverCommentEnabled && (
          <Column>
            <Container flex={1} style={{ padding: '5px 10px' }}>
              <Subtitle>
                <Translate stringId="driverComment" data-testid="driverCommentLabel" />
              </Subtitle>
              <div className="comment-section" style={{ padding: '5px' }}>
                <div data-testid="driverCommentValue">{values?.comments.driver}</div>
              </div>
            </Container>
          </Column>
        )}
        {companySetup.data?.isCustomerCommentEnabled && (
          <Column>
            <Container flex={1} style={{ padding: '5px 10px' }} onClick={handleCommentClick}>
              <Subtitle>
                <Translate stringId="customerComment" data-testid="customerCommentLabel" />
              </Subtitle>
              <div className="comment-section" style={{ padding: '5px' }}>
                {finalized &&
                !values?.comments.customer &&
                !(values?.customerStatus === 'ACCEPTED' || values?.customerStatus === 'REJECTED') ? (
                  <CustomerCommentButton onClick={handleCommentClick} />
                ) : (
                  <div data-testid="customerCommentValue">{values?.comments.customer}</div>
                )}
              </div>
            </Container>
          </Column>
        )}
        {companySetup?.data?.isMaterialComplianceEnabled && (
          <Column>
            <Container flex={1} style={{ padding: '5px 10px' }} onClick={handleCommentClick}>
              <Subtitle>
                <Translate stringId="compliance" data-testid="materialComplianceLabel" />
              </Subtitle>
              <div className="materialCompliance-section" style={{ padding: '5px' }}>
                {!!values?.productCompliance?.consistenceIsCompliant ? (
                  <i className="icon fa-light fa-square-check"></i>
                ) : (
                  <i className="icon fa-light fa-square"></i>
                )}{' '}
                <Translate stringId="consistenceIsCompliantShort" data-testid="consistenceIsCompliantLabel" />
              </div>
              <div className="materialCompliance-section" style={{ padding: '5px' }}>
                {!!values?.productCompliance?.airContentIsCompliant ? (
                  <i className="icon fa-light fa-square-check"></i>
                ) : (
                  <i className="icon fa-light fa-square"></i>
                )}{' '}
                <Translate stringId="airContentIsCompliantShort" data-testid="airContentIsCompliantLabel" />
              </div>
            </Container>
          </Column>
        )}
      </Row>
    </Styled>
  );
};
