import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { find } from 'lodash';
import { useTicketContext } from '../../../TicketContext';
import style from '../style';
import { Localization, Typography, Form } from 'connex-cds';

const { Uom, Translate } = Localization;
const { Subtitle } = Typography;

const Styled = styled.div`
  ${style}
`;

const Material = () => {
  const { ticket } = useTicketContext();

  const mixInfo = React.useMemo(() => {
    const primaryLineItem = find(ticket?.lineItems, { isPrimary: true });

    return {
      mix: primaryLineItem?.item?.id || primaryLineItem?.item?.description,
      loadedQty: <Uom uom={primaryLineItem?.quantity} />,
    };
  }, [ticket?.lineItems]);

  return (
    <Styled className={cn('material-info')}>
      <div className="table-section">
        <table>
          <thead>
            <tr>
              <th>
                <Translate stringId="mix" data-testid="mixIdDescriptionLabel" />
              </th>
              <th>
                <Translate stringId="loaded" data-testid="loadedQtyLabel" />
              </th>
              {ticket?.dispatchOrder?.totalTicketed?.value >= 0 && (
                <th>
                  <Translate stringId="loadedOrder" data-testid="loadedOrderLabel" />
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-testid="mixDescriptionValue"> {mixInfo.mix}</td>
              <td data-testid="loadedQtyValue">{mixInfo.loadedQty}</td>
              <td data-testid="loadedOrderQtyValue">
                <Uom uom={ticket?.dispatchOrder?.totalTicketed} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Styled>
  );
};

export default Material;
