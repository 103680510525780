import React from 'react';
import { useParams } from 'react-router-dom';
import { mobileTicket } from '../../../../api';
import { getMockTicket } from './getMockTicket';
import { useQueryClient } from '@tanstack/react-query';

export const useTestTicket = ({ setTicketRef }) => {
  const { entityRef, truckNumber } = useParams();
  const [busy, setBusy] = React.useState(false);
  const queryClient = useQueryClient();

  const createTicket = React.useCallback(
    async isPump => {
      setBusy(true);
      const mockTicket = getMockTicket({
        entityRef,
        truckNumber,
        ticketNumber: `${Math.floor(1000000 + Math.random() * 9000000)}`,
        isPump,
      });
      const ticket = await mobileTicket.createTestTicket({
        entityRef,
        ticket: mockTicket,
      });
      setBusy(false);
      queryClient.setQueryData(['ticket', entityRef, ticket.crn], ticket);
      setTicketRef(ticket.crn);
    },
    [entityRef, queryClient, setTicketRef, truckNumber]
  );

  return { createTicket, busy };
};
