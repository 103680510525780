import { Form } from 'connex-cds';
import { Signature } from '../signature-component/Signature';
import { TimeDrawer } from '../time-drawer/TimeDrawer';
import { useMaterialServiceSchema } from './useMaterialServiceSchema';
import { useWaterAddedSchema } from './useWaterAddedSchema';

const { FIELD_TYPES } = Form;

export const useFieldsConfig = loadedQuantity => {
  const materialServiceSchema = useMaterialServiceSchema(loadedQuantity);
  const waterAddedSchema = useWaterAddedSchema(loadedQuantity);

  return [
    {
      path: 'waterAddedEvents',
      name: 'water',
      labelStringId: 'water',
      testId: 'water',
      dataType: FIELD_TYPES.array,
      arrayOf: {
        // validationSchema: Yup.object().shape({
        //   quantity: Yup.object()
        //     .required('required')
        //     .shape({
        //       value: Yup.number().required('required').min(0),
        //       uomCode: Yup.string().required('required'),
        //     }),
        // }),
        validationSchema: waterAddedSchema,
        fields: [
          {
            path: 'waterProduct',
            name: 'waterProduct',
            labelStringId: 'waterProduct',
            dataType: Form.FIELD_TYPES.string,
            valuePath: 'productRef',
            displayPath: 'description',
            listValues: [],
            listTypeId: 'product',
            listValuePath: 'crn',
            listDisplayPath: 'description',
            testId: 'waterProduct',
          },
          {
            path: 'reason',
            valuePath: 'reasonRef',
            displayPath: 'description',
            labelStringId: 'reason',
            dataType: FIELD_TYPES.string,
            listValues: [],
            listValuePath: 'crn',
            listDisplayPath: 'description',
            testId: 'reason',
          },
          {
            path: 'quantity',
            labelStringId: 'quantity',
            dataType: FIELD_TYPES.quantity,
            uomListValues: ['LTR', 'GLL'],
            required: { message: 'required' },
            testId: 'quantity',
          },
          {
            path: 'concreteOnTruck',
            labelStringId: 'concreteOnTruck',
            dataType: FIELD_TYPES.quantity,
            uomListValues: ['MTQ', 'YDQ'],
            testId: 'concreteOnTruck',
          },
          {
            path: 'time',
            labelStringId: 'time',
            FormComponent: TimeDrawer,
            dataType: FIELD_TYPES.string,
            testId: 'time',
          },
          {
            path: 'driverDidNotAdd',
            labelStringId: 'driverDidNotAdd',
            dataType: FIELD_TYPES.boolean,
            testId: 'driverDidNotAdd',
          },
        ],
      },
    },
    {
      name: 'materialService',
      path: 'lineItems',
      labelStringId: 'materialService',
      testId: 'materialService',
      dataType: FIELD_TYPES.array,
      arrayOf: {
        validationSchema: materialServiceSchema,
        fields: [
          {
            path: 'item',
            name: 'product',
            valuePath: 'productRef',
            displayPath: 'description',
            labelStringId: 'materialService',
            dataType: FIELD_TYPES.string,
            listValues: [],
            listValuePath: 'crn',
            listDisplayPath: 'description',
            testId: 'product',
          },
          {
            path: 'quantity',
            labelStringId: 'quantity',
            dataType: FIELD_TYPES.quantity,
            uomListValues: ['EA', 'MTQ', 'LTR', 'YDQ', 'GLL'],
            testId: 'quantity',
          },
          {
            path: 'concreteOnTruck',
            labelStringId: 'concreteOnTruck',
            dataType: FIELD_TYPES.quantity,
            uomListValues: ['MTQ', 'YDQ'],
            testId: 'concreteOnTruck',
          },
          {
            path: 'reason',
            valuePath: 'reasonRef',
            displayPath: 'description',
            labelStringId: 'reason',
            dataType: FIELD_TYPES.string,
            listValues: [],
            listValuePath: 'crn',
            listDisplayPath: 'description',
            testId: 'reason',
          },
          {
            path: 'time',
            labelStringId: 'time',
            FormComponent: TimeDrawer,
            dataType: FIELD_TYPES.string,
            testId: 'time',
          },
          {
            path: 'driverDidNotAdd',
            labelStringId: 'driverDidNotAdd',
            dataType: FIELD_TYPES.boolean,
            testId: 'driverDidNotAdd',
          },
        ],
      },
    },
    {
      path: 'returnConcreteOnTruck',
      labelStringId: 'concreteOnTruck',
      dataType: FIELD_TYPES.quantity,
      uomListValues: ['MTQ', 'YDQ'],
      testId: 'concreteOnTruck',
    },
    {
      path: 'returnReason',
      valuePath: 'reasonRef',
      displayPath: 'description',
      labelStringId: 'reason',
      dataType: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'description',
      testId: 'returnReason',
    },
    {
      path: 'driverDidNotAdd',
      labelStringId: 'noRestQuantity',
      dataType: FIELD_TYPES.boolean,
      testId: 'driverDidNotAdd',
    },
    {
      path: 'totalPumpedQuantity',
      labelStringId: 'totalPumpedQuantity',
      dataType: FIELD_TYPES.quantity,
      uomListValues: ['MTQ', 'YDQ'],
      testId: 'totalPumpedQuantity',
    },
    {
      path: 'signature',
      labelStringId: 'reason',
      Component: Signature,
    },
    {
      path: 'signature.signerName',
      name: 'signatureSiteContact',
      labelStringId: 'signatureSiteContact',
      dataType: FIELD_TYPES.string,
      testId: 'signatureSiteContact',
    },
    {
      path: 'comments.driver',
      name: 'driverComment',
      labelStringId: 'driverComment',
      dataType: FIELD_TYPES.string,
      testId: 'driverComment',
      multiline: true,
    },
    {
      path: 'comments.customer',
      name: 'customerComment',
      labelStringId: 'customerComment',
      dataType: FIELD_TYPES.string,
      testId: 'customerComment',
      multiline: true,
    },
    {
      path: 'unloadingMethod',
      valuePath: 'unloadingMethodRef',
      displayPath: 'description',
      labelStringId: 'unloadingMethod',
      dataType: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'description',
      initialFocus: true,
      testId: 'unloadingMethod',
    },
    {
      path: 'driverAcceptanceReason',
      name: 'reason',
      valuePath: 'reasonRef',
      displayPath: 'description',
      labelStringId: 'reason',
      dataType: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'description',
      testId: 'driverAcceptanceReason',
    },
    {
      path: 'ticketEvents.END_UNLOADING.eventDateTime',
      name: 'endUnloading',
      labelStringId: 'END_UNLOADING',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.ARRIVE_JOB.eventDateTime',
      name: 'arriveJob',
      labelStringId: 'ARRIVE_JOB',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.PRINTED.eventDateTime',
      name: 'printed',
      labelStringId: 'PRINTED',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.LOADING_STARTED.eventDateTime',
      name: 'loadingStarted',
      labelStringId: 'LOADING_STARTED',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.LOADING_COMPLETE.eventDateTime',
      name: 'loadingComplete',
      labelStringId: 'LOADING_COMPLETE',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.TO_JOB.eventDateTime',
      name: 'toJob',
      labelStringId: 'TO_JOB',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.UNLOADING.eventDateTime',
      name: 'unloading',
      labelStringId: 'UNLOADING',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.LEAVE_JOB.eventDateTime',
      name: 'leaveJob',
      labelStringId: 'LEAVE_JOB',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.IN_YARD.eventDateTime',
      name: 'inYard',
      labelStringId: 'IN_YARD',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.PUMPING_STARTED.eventDateTime',
      name: 'pumpingStarted',
      labelStringId: 'PUMPING_STARTED',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.PUMPING_COMPLETE.eventDateTime',
      name: 'pumpingComplete',
      labelStringId: 'PUMPING_COMPLETE',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.READY_TO_PUMP.eventDateTime',
      name: 'readyToPump',
      labelStringId: 'READY_TO_PUMP',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'finalized',
      labelStringId: 'finalized',
      dataType: FIELD_TYPES.boolean,
      testId: 'finalized',
    },
    {
      path: 'customerRejectReason',
      valuePath: 'reasonRef',
      displayPath: 'description',
      labelStringId: 'reason',
      dataType: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'description',
      testId: 'customerRejectReason',
    },
    {
      path: 'customerStatus',
      labelStringId: 'customerStatus',
      dataType: FIELD_TYPES.string,
      testId: 'customerStatus',
      defaultValue: 'PENDING',
    },
    {
      path: 'productCompliance.consistenceValue',
      name: 'consistenceValue',
      labelStringId: 'consistenceValue',
      dataType: FIELD_TYPES.quantity,
      testId: 'consistenceValue',
    },
    {
      path: 'productCompliance.consistenceIsCompliant',
      name: 'consistenceIsCompliant',
      labelStringId: 'consistenceIsCompliant',
      dataType: FIELD_TYPES.boolean,
      testId: 'consistenceIsCompliant',
    },
    {
      path: 'productCompliance.airContentValue',
      name: 'airContentValue',
      labelStringId: 'airContentValue',
      dataType: FIELD_TYPES.quantity,
      testId: 'airContentValue',
    },
    {
      path: 'productCompliance.airContentIsCompliant',
      name: 'airContentIsCompliant',
      labelStringId: 'airContentIsCompliant',
      dataType: FIELD_TYPES.boolean,
      testId: 'airContentIsCompliant',
    },
  ];
};
