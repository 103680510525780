import { Form, Responsive } from 'connex-cds';
import React from 'react';

import { AcceptanceMobile } from './AcceptanceMobile';
import { AcceptanceDesktop } from './AcceptanceDesktop';

export const FLOW = {
  ACCEPT_REJECT: 'accept-reject',
  SIGNATURE_NO_SIGNATURE: 'signature-no-signature',
};

export const Acceptance = ({ handleBackClick }) => {
  const { values } = Form.useFormContext();

  // TODO: This will come from company config.
  const flow = FLOW.SIGNATURE_NO_SIGNATURE;

  const signed = React.useMemo(() => {
    return !!values?.signature?.image;
  }, [values?.signature?.image]);

  return (
    <Responsive>
      <AcceptanceMobile handleBackClick={handleBackClick} signed={signed} flow={flow} />
      <AcceptanceDesktop handleBackClick={handleBackClick} signed={signed} flow={flow} />
    </Responsive>
  );
};
