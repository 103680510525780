import cn from 'classnames';
import { Form, Core } from 'connex-cds';
import { isEmpty, omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { FLOW } from '../Acceptance';
import style from './style';

const Styled = styled.div`
  ${style}
`;
export const FinalizeActionsDesktop = ({ finalized, flow, handleAcceptClick, handleRejectClick, handleClick }) => {
  const { Components, errors } = Form.useFormContext();

  return finalized ? (
    <Styled className={cn('finalize-actions accept-reject')}>
      <div>
        {flow === FLOW.ACCEPT_REJECT ? (
          <Core.Button stringId="accept" onClick={handleAcceptClick} type="primary" data-testid="accept-button" />
        ) : (
          <Core.Button stringId="signature" onClick={handleAcceptClick} type="primary" data-testid="accept-button" />
        )}
      </div>
      <div>
        {flow === FLOW.ACCEPT_REJECT ? (
          <Core.Button
            danger
            stringId="reject"
            onClick={handleRejectClick}
            type="primary"
            data-testid="reject-button"
          />
        ) : (
          <Components.SubmitButton
            stringId="no-signature"
            data-testid="no-signature-button"
            type="primary"
            danger
            disabledWhenNotDirty={false}
            disabled={!isEmpty(omit(errors, 'ticketEvents'))}
          />
        )}
      </div>
    </Styled>
  ) : (
    <Styled className={cn('finalize-actions finalize')}>
      <div>
        <Core.Button stringId="finalize" onClick={handleClick} type="primary" data-testid="finalize-button" />
      </div>
    </Styled>
  );
};
