import cn from 'classnames';
import { Form, Drawer } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { find, omit, values } from 'lodash';
import { getUserRbac, getUserRole } from '../../../../../query-hooks/users';
import { useListCarriers } from '../../../../../query-hooks/carriers';
import { useListRoles, useUpdateUserRole } from '../../../../../query-hooks/roles';

import style from './style';

const Styled = styled.div`
  ${style}
`;

const getInterimOptions = (currentFormValue, carriersQuery) => {
  if (!values(currentFormValue)?.join('')?.trim?.()?.length) return [];

  let carriers = [];
  if (currentFormValue?.length) {
    for (let carrier of currentFormValue) {
      const foundCarrier = find(carriersQuery?.data, { crn: carrier });
      if (foundCarrier) {
        carriers.push({ name: foundCarrier?.name, crn: foundCarrier?.crn, id: foundCarrier?.id });
      }
    }
  }

  return carriers;
};

export const UserEditor = () => {
  const { values, Components, setFieldValue } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const [busy, setBusy] = React.useState(false);
  const [allCarriers, setAllCarriers] = React.useState(null);

  const carriersQuery = useListCarriers();
  const [options, setOptions] = React.useState(getInterimOptions(values?.carriers), carriersQuery);

  const userInitialRole = getUserRole(values?.toRef);
  const rolesQuery = useListRoles();

  const userRbac = getUserRbac(values?.toRef);
  const updateUserRole = useUpdateUserRole(values?.toRef);

  // Get the user's role set the default value
  React.useEffect(() => {
    if (userInitialRole?.data && rolesQuery?.data) {
      let currentRole;
      for (let userInitRole of userInitialRole?.data) {
        const foundRole = find(rolesQuery?.data, { crn: userInitRole?.crn });
        if (foundRole) {
          currentRole = foundRole;
        }
      }
      setFieldValue('role', { ...omit(currentRole, 'value'), roleRef: currentRole?.crn });
    }
  }, [userInitialRole?.data, rolesQuery?.data, values?.fromRef, setFieldValue]);

  // Get the user's carriers set the default value
  React.useEffect(() => {
    if (userRbac?.data?.carriers?.length) {
      let currentCarriers = [];
      for (let userInitCarrier of userRbac?.data?.carriers) {
        currentCarriers.push(userInitCarrier?.carrierRef);
      }
      setFieldValue('carriers', currentCarriers);
    }
  }, [setFieldValue, userRbac?.data]);

  const handleEdit = React.useCallback(async () => {
    setBusy(true);

    let carriers = [];
    if (values?.carriers?.length) {
      for (let carrier of values?.carriers) {
        const foundCarrier = find(carriersQuery.data, { crn: carrier });
        if (foundCarrier) {
          carriers.push({ name: foundCarrier?.name, carrierRef: foundCarrier?.crn, id: foundCarrier?.id });
        }
      }
    }

    const response = await updateUserRole({
      carriers: carriers,
      entityRef: values?.fromRef,
      profileRef: values?.toRef,
      roleRef: values?.role?.roleRef,
    });
    setBusy(false);

    if (response?.success === 'ok') {
      closeDrawer();
    }
  }, [
    carriersQuery.data,
    closeDrawer,
    updateUserRole,
    values?.carriers,
    values?.fromRef,
    values?.role?.roleRef,
    values?.toRef,
  ]);

  const _options = React.useMemo(() => {
    const allCarriers = carriersQuery?.data;

    setAllCarriers(allCarriers);

    return allCarriers?.filter?.(carrier => carrier.status === 'ACTIVE');
  }, [carriersQuery?.data]);

  const value = React.useMemo(() => {
    return values?.carriers || undefined;
  }, [values?.carriers]);

  const ensureOptionsContainCurrent = React.useCallback(
    (options = []) => {
      let currentValues = [];
      if (value?.length) {
        for (let carrier of value) {
          const targetCarrierRef = carrier;
          const targetCarrier = find(allCarriers, { crn: carrier });

          if (targetCarrier) {
            const isValueAlreadyInList = !!find(options, { crn: targetCarrierRef });
            if (!isValueAlreadyInList) {
              currentValues.push(targetCarrier);
            }
          }
        }
        return currentValues?.length ? options.concat(currentValues) : options;
      }
      return options;
    },
    [allCarriers, value]
  );

  React.useEffect(() => {
    setOptions(ensureOptionsContainCurrent(_options || []) || []);
  }, [_options, ensureOptionsContainCurrent, values?.carriers]);

  return (
    <Styled className={cn('user-editor')}>
      <Components.FirstName disabled />
      <Components.LastName disabled />
      <Components.Email disabled />
      <Components.Phone disabled />
      {values?.status === 'REGISTERED' && <Components.Role options={rolesQuery?.data} busy={rolesQuery?.isLoading} />}
      {values?.role?.name?.toLowerCase() === 'driver' && (
        <Components.Carriers options={options || []} busy={carriersQuery?.isLoading} />
      )}
      <div className="actions">
        <div>
          <Components.CancelButton onCancel={closeDrawer} enabled />
          {values?.status === 'REGISTERED' && <Components.SaveButton onUpdate={handleEdit} suppressDisabledStyling />}
        </div>
      </div>
    </Styled>
  );
};
