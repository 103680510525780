import cn from 'classnames';
import { Core, Drawer, Form, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import messages from '../../../../../../../i18n';
import { useTicketContext } from '../../../TicketContext';
import { Activity } from './activity/Activity';
import { Comments } from './comments/Comments';
import { FinalizeSection } from './finalize-section/FinalizeSection';
import { Header } from './header/Header';
import { LogisticalInfo } from './logistical-info/LogisticalInfo';

const Styled = styled.div`
  padding: 5px;
  & > * {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
  .header {
    margin: 10px 5px;
    height: 20px;
    align-items: center;
    h3 {
      margin: 0;
    }
  }
`;

export const AcceptanceMobile = ({ handleBackClick, signed, flow }) => {
  const { userLocale, timeZone } = Localization.useLocalizationContext();
  const { finalized } = useTicketContext();
  const { values } = Form.useFormContext();

  return (
    <Localization.Localization messages={messages} initialLocale={userLocale} initialTimeZone={timeZone}>
      <Drawer.DrawerProvider>
        <Styled className={cn('acceptance-mobile')}>
          {finalized && (
            <div className="header">
              {signed ? null : (
                <Core.Button
                  size="small"
                  type="primary"
                  stringId="back"
                  data-testid="back-button"
                  onClick={handleBackClick}
                />
              )}
              <Header flow={flow} signed={signed} />
            </div>
          )}
          {finalized && (
            <div style={{ padding: '0px 10px' }}>
              <Typography.H3>
                <Localization.Translate stringId="finalize" data-testid="acceptance-title" />
              </Typography.H3>
            </div>
          )}
          <LogisticalInfo ticket={values} />
          <Activity />
          <Comments />
          <FinalizeSection ticketId={values.id} flow={flow} />
        </Styled>
      </Drawer.DrawerProvider>
    </Localization.Localization>
  );
};
