import { Form, Localization } from 'connex-cds';
import { CompanyEditor } from '../custom-editors/company-editor/CompanyEditor';
import * as yup from 'yup';
import defaultQrCodeTemplate from '../../delivery-information/defaultQrCodeTemplate';

const { FIELD_TYPES } = Form;

const fields = {
  icon: <i className="icon fa-thin fa-industry-windows" />,
  noList: true,
  customEditor: props => <CompanyEditor {...props} />,
  validationSchema: yup.object().shape({
    maxLineItems: yup.number().min(1, 'oneLineItem'),
    pollingInterval: yup.number().test({
      name: 'max',
      exclusive: true,
      message: 'tenSecs',
      test: value => !value || value >= 10,
    }),
  }),
  fields: [
    {
      path: 'isWaterMandatory',
      name: 'waterMandatory',
      labelStringId: 'waterMandatory',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'waterMandatory',
    },
    {
      path: 'timeZone',
      labelStringId: 'timeZone',
      dataType: Form.FIELD_TYPES.timeZone,
      testId: 'timeZone',
    },
    {
      path: 'waterProductType',
      name: 'waterProductType',
      labelStringId: 'waterProductType',
      dataType: Form.FIELD_TYPES.string,
      valuePath: 'productTypeRef',
      displayPath: 'description',
      listValues: [],
      listTypeId: 'product-type',
      listValuePath: 'crn',
      listDisplayPath: 'name',
      testId: 'waterProductType',
    },
    {
      path: 'defaultWaterProduct',
      name: 'defaultWaterProduct',
      labelStringId: 'defaultWaterProduct',
      dataType: Form.FIELD_TYPES.string,
      valuePath: 'productRef',
      displayPath: 'description',
      listValues: [],
      listTypeId: 'product',
      listValuePath: 'crn',
      listDisplayPath: 'description',
      testId: 'defaultWaterProduct',
    },
    {
      path: 'qrCodeTemplate',
      labelStringId: 'qrCodeTemplate',
      testId: 'qrCodeTemplate',
      dataType: FIELD_TYPES.string,
      multiline: true,
      defaultValue: defaultQrCodeTemplate,
    },
    {
      path: 'isRefreshEnabled',
      name: 'refreshEnabled',
      labelStringId: 'refreshEnabled',
      dataType: Form.FIELD_TYPES.boolean,
      defaultValue: true,
      testId: 'refreshEnabled',
    },
    {
      path: 'concreteOnTruckUom',
      labelStringId: 'concreteOnTruckUom',
      testId: 'concreteOnTruckUom',
      listValuePath: 'id',
      listDisplayPath: 'description',
      dataType: FIELD_TYPES.string,
      listTypeId: 'uom',
      listValues: [],
      formatter: props => <Localization.Translate stringId={`${props.row.uomCode}_text`} data-testid="" />,
    },
    {
      path: 'isCustomerCommentEnabled',
      name: 'customerCommentEnabled',
      labelStringId: 'customerCommentEnabled',
      dataType: Form.FIELD_TYPES.boolean,
      defaultValue: true,
      testId: 'customerCommentEnabled',
    },
    {
      path: 'customerCommentMaxCharacters',
      labelStringId: 'customerCommentMaxCharacters',
      testId: 'customerCommentMaxCharacters',
      dataType: FIELD_TYPES.number,
    },
    {
      path: 'driverCommentMaxCharacters',
      labelStringId: 'driverCommentMaxCharacters',
      testId: 'driverCommentMaxCharacters',
      dataType: FIELD_TYPES.number,
    },
    {
      path: 'isDriverCommentEnabled',
      name: 'driverCommentEnabled',
      labelStringId: 'driverCommentEnabled',
      testId: 'driverCommentEnabled',
      dataType: FIELD_TYPES.boolean,
      defaultValue: true,
    },
    {
      path: 'maxLineItems',
      labelStringId: 'maxLineItems',
      testId: 'maxLineItems',
      dataType: FIELD_TYPES.number,
      defaultValue: 10,
    },
    {
      path: 'countries',
      labelStringId: 'countries',
      testId: 'countries',
      dataType: FIELD_TYPES.array,
      arrayOf: FIELD_TYPES.string,
      listValues: [],
    },
    {
      path: 'hidePrintedEvent',
      labelStringId: 'hideEvent',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'hidePrintedEvent',
    },
    {
      path: 'isPrintedEditable',
      labelStringId: 'editable',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'isPrintedEditable',
    },
    {
      path: 'hideLoadingStartedEvent',
      labelStringId: 'hideEvent',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'hideLoadingStartedEvent',
    },
    {
      path: 'isLoadingStartedEditable',
      labelStringId: 'editable',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'isLoadingStartedEditable',
    },
    {
      path: 'hideLoadingCompleteEvent',
      labelStringId: 'hideEvent',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'hideLoadingCompleteEvent',
    },
    {
      path: 'isLoadingCompleteEditable',
      labelStringId: 'editable',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'isLoadingCompleteEditable',
    },
    {
      path: 'hideToJobEvent',
      labelStringId: 'hideEvent',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'hideToJobEvent',
    },
    {
      path: 'isToJobEditable',
      labelStringId: 'editable',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'isToJobEditable',
    },
    {
      path: 'hideArriveJobEvent',
      labelStringId: 'hideEvent',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'hideArriveJobEvent',
    },
    {
      path: 'isArriveJobEditable',
      labelStringId: 'editable',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'isArriveJobEditable',
    },
    {
      path: 'hideUnloadingEvent',
      labelStringId: 'hideEvent',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'hideUnloadingEvent',
    },
    {
      path: 'isUnloadingEditable',
      labelStringId: 'editable',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'isUnloadingEditable',
    },
    {
      path: 'hideEndUnloadingEvent',
      labelStringId: 'hideEvent',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'hideEndUnloadingEvent',
    },
    {
      path: 'isEndUnloadingEditable',
      labelStringId: 'editable',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'isEndUnloadingEditable',
    },
    {
      path: 'hideLeaveJobEvent',
      labelStringId: 'hideEvent',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'hideLeaveJobEvent',
    },
    {
      path: 'isLeaveJobEditable',
      labelStringId: 'editable',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'isLeaveJobEditable',
    },
    {
      path: 'hideInYardEvent',
      labelStringId: 'hideEvent',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'hideInYardEvent',
    },
    {
      path: 'isInYardEditable',
      labelStringId: 'editable',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'isInYardEditable',
    },
    {
      path: 'hidePumpingStartedEvent',
      labelStringId: 'hideEvent',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'hidePumpingStartedEvent',
    },
    {
      path: 'isPumpingStartedEditable',
      labelStringId: 'editable',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'isPumpingStartedEditable',
    },
    {
      path: 'hidePumpingCompleteEvent',
      labelStringId: 'hideEvent',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'hidePumpingCompleteEvent',
    },
    {
      path: 'isPumpingCompleteEditable',
      labelStringId: 'editable',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'isPumpingCompleteEditable',
    },
    {
      path: 'hideReadyToPumpEvent',
      labelStringId: 'hideEvent',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'hideReadyToPumpEvent',
    },
    {
      path: 'isReadyToPumpEditable',
      labelStringId: 'editable',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'isReadyToPumpEditable',
    },
    {
      path: 'hideFirstWaterCementContact',
      labelStringId: 'hideEvent',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'hideWCContact',
    },
    {
      path: 'unFinalizePassword',
      labelStringId: 'unFinalizePassword',
      dataType: Form.FIELD_TYPES.string,
      testId: 'unFinalizePassword',
    },
    {
      path: 'isWaterInWorkflow',
      name: 'waterInWorkflow',
      labelStringId: 'waterInWorkflow',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'waterInWorkflow',
      defaultValue: true,
    },
    {
      path: 'isMulticountry',
      name: 'multicountry',
      labelStringId: 'multicountry',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'multiCountry',
    },
    {
      path: 'isReturnedMaterialMandatory',
      name: 'returnedMaterialMandatory',
      labelStringId: 'returnedMaterialMandatory',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'returnedMaterialMandatory',
    },
    {
      path: 'isQrCodeEnabled',
      name: 'QrCodeEnabled',
      labelStringId: 'QrCodeEnabled',
      dataType: Form.FIELD_TYPES.boolean,
      defaultValue: true,
      testId: 'QrCodeEnabled',
    },
    {
      path: 'isReturnedMaterialInWorkflow',
      name: 'returnedMaterialInWorkflow',
      labelStringId: 'returnedMaterialInWorkflow',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'returnedMaterialInWorkflow',
      defaultValue: true,
    },
    {
      path: 'isMaterialComplianceEnabled',
      name: 'materialComplianceEnabled',
      labelStringId: 'materialComplianceEnabled',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'materialComplianceEnabled',
    },
    {
      path: 'pollingInterval',
      labelStringId: 'pollingInterval',
      testId: 'pollingInterval',
      dataType: FIELD_TYPES.number,
    },
    {
      path: 'trackit.context',
      name: 'context',
      labelStringId: 'trackIt3PContext',
      dataType: Form.FIELD_TYPES.string,
      testId: 'trackIt3PContext',
    },
    {
      path: 'isMaterialServiceInWorkflow',
      name: 'materialServiceInWorkflow',
      labelStringId: 'materialServiceInWorkflow',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'materialServiceInWorkflow',
      defaultValue: true,
    },
    {
      path: 'isUnloadingMethodEnabled',
      name: 'unloadingMethodEnabled',
      labelStringId: 'unloadingMethodEnabled',
      dataType: Form.FIELD_TYPES.boolean,
      testId: 'unloadingMethodEnabled',
      defaultValue: true,
    },
  ],
};

export default fields;
