import cn from 'classnames';
import { Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import style from '../style';

const { Translate, Uom, ShortDateTime } = Localization;

const Styled = styled.div`
  ${style}
`;
const GeneralDeliveryInfo = ({ data }) => {
  let loadedQty = '';
  if (data?.lineItems && data?.lineItems.length > 0) {
    data?.lineItems.forEach((lineItem, key) => {
      if (lineItem.isPrimary) {
        loadedQty = <Uom uom={lineItem.quantity} />;
      }
    });
  }

  return (
    <Styled className={cn('test-form')}>
      {data?.origin?.description && (
        <div className="body">
          <Translate stringId="plantName" data-testid="plantNameLabel" /> :{' '}
          <span data-testid="plantName"> {data?.origin?.description} </span>
        </div>
      )}
      {data?.id && (
        <div className="body">
          <Translate stringId="sapDeliveryNumber" data-testid="sapDeliveryNumberLabel" /> :{' '}
          <span data-testid="sapDeliveryNumber"> {data?.id}</span>
        </div>
      )}
      {data?.originatorRecordId && (
        <div className="body">
          <Translate stringId="salesOrderNumber" data-testid="salesOrderNumberLabel" /> :{' '}
          <span data-testid="salesOrderNumber"> {data?.originatorRecordId}</span>
        </div>
      )}
      {data?.customData?.productDetails?.wcContactDateTime && (
        <div className="body">
          <Translate stringId="wcDateTime" data-testid="wcDateTimeLabel" /> :{' '}
          <span data-testid="wcDateTime">
            <ShortDateTime date={data?.customData?.productDetails?.wcContactDateTime} />
          </span>
        </div>
      )}
      {data?.purchaseOrder && (
        <div className="body">
          <Translate stringId="po" data-testid="poLabel" /> : <span data-testid="po">{data?.purchaseOrder} </span>
        </div>
      )}
      {data?.customData?.orderDetails?.customerPoNumber && (
        <div className="body">
          <Translate stringId="customerPo" data-testid="customerPoLabel" /> :{' '}
          <span data-testid="customerPo">{data?.customData?.orderDetails?.customerPoNumber} </span>
        </div>
      )}
      {data?.customData?.orderDetails?.orderPlaceBy && (
        <div className="body">
          <Translate stringId="orderedByName" data-testid="orderedByNameLabel" /> :{' '}
          <span data-testid="orderedByName"> {data?.customData?.orderDetails?.orderPlaceBy}</span>
        </div>
      )}
      {data?.customData?.orderDetails?.orderPlaceByPhone && (
        <div className="body">
          <Translate stringId="orderedByPhone" data-testid="orderedByPhoneLabel" /> :{' '}
          <span data-testid="orderedByPhone"> {data?.customData?.orderDetails?.orderPlaceByPhone}</span>
        </div>
      )}
      {data?.sk?.vehicleId && (
        <div className="body">
          <Translate stringId="resourceId" data-testid="resourceIdLabel" /> :{' '}
          <span data-testid="resourceId"> {data?.sk?.vehicleId}</span>
        </div>
      )}
      {loadedQty && (
        <div className="body">
          <Translate stringId="loadedQtyLabel" data-testid="loadedQtyLabel" /> :{' '}
          <span data-testid="loadedQty">{loadedQty}</span>
        </div>
      )}
      {data?.dispatchOrder?.totalTicketed?.value >= 0 && (
        <div className="body">
          <Translate stringId="totalLoadedQty" data-testid="totalLoadedQtyLabel" /> :{' '}
          <span data-testid="totalLoadedQty">
            <Uom uom={data?.dispatchOrder?.totalTicketed} />
          </span>
        </div>
      )}
      {data?.dispatchOrder?.totalOrdered?.value >= 0 && (
        <div className="body">
          <Translate stringId="orderedQtyLabel" data-testid="orderedQtyLabel" /> :{' '}
          <span data-testid="orderedQty">
            <Uom uom={data?.dispatchOrder?.totalOrdered} />
          </span>
        </div>
      )}
      {data?.dispatchOrder?.deliverySchedule?.deliverySpacing?.value >= 0 && (
        <div className="body">
          <Translate stringId="spacing" data-testid="spacingLabel" /> :{' '}
          <span data-testid="spacing"> {data?.dispatchOrder?.deliverySchedule?.deliverySpacing?.value}</span>
        </div>
      )}
      {data?.customData?.orderDetails?.deliveryFlow && (
        <div className="body">
          <Translate stringId="deliveryFlow" data-testid="deliveryFlowLabel" /> :{' '}
          <span data-testid="deliveryFlow"> {data?.customData?.orderDetails?.deliveryFlow}</span>
        </div>
      )}
      {data?.unloadingMethod?.description && (
        <div className="body">
          <Translate stringId="unloadingMethod" data-testid="unloadingMethodLabel" /> :{' '}
          <span data-testid="unloadingMethod"> {data?.unloadingMethod?.description}</span>
        </div>
      )}
      {data?.customData?.orderDetails?.unloadingTime && (
        <div className="body">
          <Translate stringId="timeOnSite" data-testid="timeOnSiteLabel" /> :{' '}
          <span data-testid="timeOnSite"> {data?.customData?.orderDetails?.unloadingTime}</span>
        </div>
      )}
    </Styled>
  );
};

export default GeneralDeliveryInfo;
