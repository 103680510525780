export default [
  { id: 'users', labelStringId: 'users', path: '/users', icon: 'fa-thin fa-users' },
  { id: 'setup', labelStringId: 'setup', path: '/setup', icon: 'fa-light fa-gear' },
  {
    id: 'driver',
    labelStringId: 'driverExperience',
    path: '/driver',
    icon: 'fa-regular fa-steering-wheel',
    disabled: false,
  },
];
