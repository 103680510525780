import cn from 'classnames';
import { Form, Drawer, Responsive } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useTicketContext } from '../../../../TicketContext';
import style from './style';
import { AcceptanceSignature } from './accept/AcceptanceSignature';
import { RejectTicket } from './reject/RejectTicket';
import { FinalizeActionsMobile } from './FinalizeActionsMobile';
import { FinalizeActionsDesktop } from './FinalizeActionsDesktop';

export const FinalizeActions = ({ ticketId, flow }) => {
  const { setFieldValue } = Form.useFormContext();
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();
  const { finalized, setFinalized } = useTicketContext();

  const handleAcceptCancel = React.useCallback(() => {
    setFieldValue('signature', undefined);
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const handleRejectCancel = React.useCallback(() => {
    setFieldValue('customerRejectReason', undefined);
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const handleAcceptClick = React.useCallback(() => {
    openDrawer({
      onClose: handleAcceptCancel,
      titleStringId: 'customerSignature',
      component: <AcceptanceSignature ticketId={ticketId} onCancel={handleAcceptCancel} />,
      width: '100%',
    });
  }, [handleAcceptCancel, openDrawer, ticketId]);

  const handleRejectClick = React.useCallback(() => {
    openDrawer({
      onClose: handleRejectCancel,
      titleStringId: 'customerRejection',
      component: <RejectTicket onCancel={handleRejectCancel} />,
      width: '700px',
    });
  }, [handleRejectCancel, openDrawer]);

  const handleClick = React.useCallback(() => {
    setFinalized(true);
    setFieldValue('finalized', true);
  }, [setFieldValue, setFinalized]);

  return (
    <Responsive>
      <FinalizeActionsMobile
        finalized={finalized}
        flow={flow}
        handleAcceptClick={handleAcceptClick}
        handleRejectClick={handleRejectClick}
        handleClick={handleClick}
      />
      <FinalizeActionsDesktop
        finalized={finalized}
        flow={flow}
        handleAcceptClick={handleAcceptClick}
        handleRejectClick={handleRejectClick}
        handleClick={handleClick}
      />
    </Responsive>
  );
};
