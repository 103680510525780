import cn from 'classnames';
import { Form, Layout, Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import style from '../style';

const { Column, Row, Container } = Layout;
const { Translate } = Localization;

const Styled = styled.div`
  ${style}
`;

export const ReturnedMaterialsDesktop = ({ mixInfo, concreteOnTruckUom, disabled, options, reasonCodeSetup }) => {
  const {
    Components: { ReturnReason, ReturnConcreteOnTruck, DriverDidNotAdd },
  } = Form.useFormContext();

  return (
    <Styled className={cn('returned-materials')}>
      <Column>
        <Row>
          <Container flex={1}>
            <Column>
              <div className="body">
                <Translate stringId="mixIdLabel" data-testid="mixIdDescriptionLabel" /> :{' '}
                <span data-testid="mixIdDescriptionValue"> {mixInfo.mix} </span>
              </div>
              <div className="body">
                <Translate stringId="loadedQtyLabel" data-testid="loadedQtyLabel" /> :{' '}
                <span data-testid="loadedQtyValue"> {mixInfo.loadedQty} </span>
              </div>
            </Column>
          </Container>
        </Row>
        <Row>
          <Column>
            <ReturnConcreteOnTruck uomCode={concreteOnTruckUom} disabled={disabled} />
          </Column>
        </Row>
        <Row>
          <Column>
            <ReturnReason options={options} busy={reasonCodeSetup.isLoading} showSearch={false} disabled={disabled} />
          </Column>
        </Row>
        <Row>
          <Column>
            <DriverDidNotAdd />
          </Column>
        </Row>
      </Column>
    </Styled>
  );
};
