import { Form, Responsive } from 'connex-cds';
import React from 'react';
import { useTicketContext } from '../../../TicketContext';
import { ListDesktop } from './desktop/ListDesktop';
import { ListMobile } from './mobile/ListMobile';

export const CustomListComponent = props => {
  const {
    deleteItem,
    resetForm,
    parentFormContext: { errors },
  } = Form.useFormContext();

  const { ticket } = useTicketContext();

  const handleDeleteClick = React.useCallback(
    item => {
      deleteItem(item);
      resetForm({ reinitialize: true });
    },
    [deleteItem, resetForm]
  );

  const handleRowClick = React.useCallback(
    row => {
      if (
        Object.keys(ticket?.ticketEvents)?.filter(event => ['END_UNLOADING', 'PUMPING_COMPLETE'].includes(event))
          ?.length !== 0 ||
        !row.suggestedToDriver
      ) {
        props.onRowClick?.(row);
      }
    },
    [props, ticket?.ticketEvents]
  );

  const disabled = React.useCallback(
    item => {
      return (
        Object.keys(ticket?.ticketEvents)?.filter(event => ['END_UNLOADING', 'PUMPING_COMPLETE'].includes(event))
          ?.length === 0 && item?.suggestedToDriver
      );
    },
    [ticket?.ticketEvents]
  );

  const listProps = React.useMemo(() => {
    return {
      ...props,
      handleDeleteClick,
      handleRowClick,
      disabled,
      errors,
    };
  }, [disabled, errors, handleDeleteClick, handleRowClick, props]);

  return (
    <Responsive>
      <ListMobile {...listProps} />
      <ListDesktop {...listProps} />
    </Responsive>
  );
};
