import { css } from 'styled-components';

export default css`
  gap: 0;
  & > * {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .comment-section {
    margin-top: 10px;
  }
  .comment-date-time {
    font-weight: bold;
    display: flex;
    & > *:first-child {
      flex: 1;
    }
  }
  .actions {
    margin: auto;
    margin-top: 10px;
  }
`;
