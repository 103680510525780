import { useQueryClient } from '@tanstack/react-query';
import { set } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { masterData } from '../../../../../../../api';
import { useCompanySetup } from '../../../MasterDataProvider';

export const useListValues = config => {
  const { entityRef } = useParams();
  const [busy, setBusy] = React.useState(false);
  const [configWithLists, setConfigWithLists] = React.useState(null);

  const queryClient = useQueryClient();
  const companySetup = useCompanySetup();

  React.useEffect(() => {
    config?.fields?.[0]?.arrayOf?.fields?.forEach?.(field => {
      if (field.listValues && field.listTypeId) {
        queryClient.prefetchQuery({
          queryKey: ['setup', entityRef, field.listTypeId],
          queryFn: () => masterData.getMasterData({ entityRef, typeId: field.listTypeId }),
          staleTime: Infinity,
        });
      }
    });
  }, [config?.fields, entityRef, queryClient]);

  const setFieldsListValues = React.useCallback(
    async fields => {
      return await fields
        ?.filter(c => (companySetup?.data?.isMulticountry !== true ? c.path !== 'countries' : c))
        ?.filter(c =>
          companySetup?.data?.isReturnedMaterialInWorkflow !== true ? c.path !== 'isReturnedMaterialInWorkflow' : c
        )
        ?.filter(c => (companySetup?.data?.isWaterInWorkflow !== true ? c.path !== 'isWaterInWorkflow' : c))
        ?.filter(c =>
          companySetup?.data?.isMaterialServiceInWorkflow !== true ? c.path !== 'isMaterialServiceInWorkflow' : c
        )
        ?.filter(c =>
          companySetup?.data?.isMaterialComplianceEnabled !== true ? c.path !== 'isMaterialComplianceEnabled' : c
        )
        ?.filter(c =>
          companySetup?.data?.isUnloadingMethodEnabled !== true ? c.path !== 'isUnloadingMethodEnabled' : c
        )
        ?.reduce?.(async (acc, field) => {
          let fieldsArray = await acc;

          const data = { ...field };

          if (field.listValues && field.listTypeId) {
            data.listValues = field.listValues.length
              ? field.listValues
              : await queryClient.fetchQuery({
                  queryKey: ['setup', entityRef, field.listTypeId],
                  queryFn: () => masterData.getMasterData({ entityRef, typeId: field.listTypeId }),
                  staleTime: Infinity,
                });
          }

          fieldsArray.push(data);
          return await fieldsArray;
        }, Promise.resolve([]));
    },
    [entityRef, queryClient]
  );

  // TODO: work with groups.

  React.useEffect(() => {
    setBusy(true);
    setFieldsListValues(config?.fields?.[0]?.arrayOf?.fields)
      .then(response => {
        set(config, 'fields.0.arrayOf.fields', response);
        setConfigWithLists(config);
      })
      .finally(() => {
        setBusy(false);
      });
  }, [config, setFieldsListValues]);

  return { busy, configWithLists };
};
