import { http } from 'connex-cds';
import { getLocationInfo } from '../../util/getLocationInfo';

export default {
  listUsers: ({ entityRef }) => http.get({ apiName: 'mt', path: `/${entityRef}/users` }),
  inviteUser: ({ entityRef, user }) =>
    http.post({
      apiName: 'mt',
      path: `/${entityRef}/users/invite`,
      data: { ...user, locationInfo: getLocationInfo() },
    }),
  register: ({ entityRef, payload }) =>
    http.post({ apiName: 'mt', path: `/users/register/${entityRef}`, data: payload }),
  getUserRole: profileRef => http.get({ apiName: 'mt', path: `/profiles/${profileRef}/roles` }),
  getUserRbac: ({ entityRef, profileRef }) =>
    http.get({ apiName: 'mt', path: `/admin/${entityRef}/users/${profileRef}/rbac` }),
};
