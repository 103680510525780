import cn from 'classnames';
import { Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useMaterialComplianceSetup } from '../../../MasterDataProvider';

const { Column, Row, Container } = Layout;

const Styled = styled.div`
  padding: 10px 20px;
`;

export const MaterialComplianceMobile = () => {
  const {
    Components: { ConsistenceValue, ConsistenceIsCompliant, AirContentValue, AirContentIsCompliant },
    values,
  } = Form.useFormContext();

  const [airUom, setAirUom] = React.useState();
  const [consistenceUom, setConsistenceUom] = React.useState();

  const materialComplianceSetup = useMaterialComplianceSetup();

  const airCompliance = React.useMemo(() => {
    if (materialComplianceSetup.isSuccess) {
      return (
        materialComplianceSetup?.data
          ?.filter?.(compliance => compliance.status === 'ACTIVE')
          ?.filter?.(compliance => compliance.classType === 'AIR') || []
      );
    }
    return [];
  }, [materialComplianceSetup?.data, materialComplianceSetup.isSuccess]);

  const consistenceCompliance = React.useMemo(() => {
    if (materialComplianceSetup.isSuccess) {
      return (
        materialComplianceSetup?.data
          ?.filter?.(compliance => compliance.status === 'ACTIVE')
          ?.filter?.(compliance => compliance.classType === 'CONSISTENCE') || []
      );
    }
    return [];
  }, [materialComplianceSetup?.data, materialComplianceSetup.isSuccess]);

  React.useEffect(() => {
    const airUomId = airCompliance?.[0]?.uomCode;
    setAirUom(airUomId);
  }, [airCompliance]);

  React.useEffect(() => {
    const consistenceUomId = consistenceCompliance?.[0]?.uomCode;
    setConsistenceUom(consistenceUomId);
  }, [consistenceCompliance]);

  const consistenceDisabled = React.useMemo(() => {
    return !values?.productCompliance?.consistenceValue?.value;
  }, [values?.productCompliance?.consistenceValue]);

  const airDisabled = React.useMemo(() => {
    return !values?.productCompliance?.airContentValue?.value;
  }, [values?.productCompliance?.airContentValue]);

  return (
    <Styled className={cn('material-compliance')}>
      <Container>
        <Column>
          <Row>
            <Column>
              <ConsistenceValue uomCode={consistenceUom} />
            </Column>
          </Row>
          <Row style={{ padding: '0px 0px 20px 0px' }}>
            <Column>
              <ConsistenceIsCompliant disabled={consistenceDisabled} />
            </Column>
          </Row>
          <Row>
            <Column>
              <AirContentValue uomCode={airUom} />
            </Column>
          </Row>
          <Row style={{ padding: '0px 0px 20px 0px' }}>
            <Column>
              <AirContentIsCompliant disabled={airDisabled} />
            </Column>
          </Row>
        </Column>
      </Container>
    </Styled>
  );
};
