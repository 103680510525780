import { Form, Localization } from 'connex-cds';
import * as Yup from 'yup';
import { FILTER_STRATEGY } from './standard';
import { MaterialComplianceEditor } from '../custom-editors/material-compliance-editor/MaterialComplianceEditor';

const { FIELD_TYPES } = Form;

const fields = {
  icon: <i className="icon fa-thin fa-layer-group" />,
  validationSchema: Yup.object().shape({
    id: Yup.string().required('required'),
    name: Yup.string().required('required'),
    classType: Yup.string().required('required'),
    minimumValue: Yup.string().required('required'),
    maximumValue: Yup.string().required('required'),
    uomCode: Yup.string().required('required'),
    status: Yup.string().required('required'),
  }),
  customEditor: MaterialComplianceEditor,
  fields: [
    { path: 'id', labelStringId: 'id', testId: 'id', dataType: FIELD_TYPES.string },
    { path: 'name', labelStringId: 'name', testId: 'name', dataType: FIELD_TYPES.string },
    { path: 'description', labelStringId: 'description', testId: 'description', dataType: FIELD_TYPES.string },
    {
      path: 'classType',
      labelStringId: 'classType',
      testId: 'classType',
      dataType: FIELD_TYPES.string,
      listValues: [
        { id: 'AIR', label: 'Air' },
        { id: 'CONSISTENCE', label: 'Consistence' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="AIR">Air</option>
            <option value="CONSISTENCE">Consistence</option>
          </select>
        );
      },
    },
    { path: 'minimumValue', labelStringId: 'minimumValue', testId: 'minimumValue', dataType: FIELD_TYPES.string },
    { path: 'maximumValue', labelStringId: 'maximumValue', testId: 'maximumValue', dataType: FIELD_TYPES.string },
    {
      path: 'uomCode',
      labelStringId: 'uom',
      testId: 'uomCode',
      listValuePath: 'id',
      listDisplayPath: 'description',
      dataType: FIELD_TYPES.string,
      listTypeId: 'uom',
      listValues: [],
      formatter: props => (
        <div data-testclass={'cell-column-uomCode'} data-testid={`row-${props.row.crn}-column-uomCode`}>
          <Localization.Translate stringId={`${props.row.uomCode}_text`} data-testid="" />
        </div>
      ),
    },
    {
      path: 'status',
      labelStringId: 'status',
      testId: 'status',
      dataType: FIELD_TYPES.string,
      listValues: [
        { id: 'ACTIVE', label: 'Active' },
        { id: 'INACTIVE', label: 'Inactive' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
      defaultValue: 'ACTIVE',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        );
      },
    },
  ],
};

export default fields;
