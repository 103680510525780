import cn from 'classnames';
import { Core, Form, Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useProductSetup, useProductTypeSetup, useUomSetup } from '../../../../MasterDataProvider';
import style from './style';

const { Column, Row, Container } = Layout;

const Styled = styled.div`
  ${style}
`;

export const Concrete = ({ busy, onSave }) => {
  const { Components, values, setFieldValue } = Form.useFormContext();

  const productSetup = useProductSetup();
  const productTypeSetup = useProductTypeSetup();
  const uomSetup = useUomSetup();

  const waterProducts = React.useMemo(() => {
    if (values.waterProductType && productSetup.isSuccess) {
      return (
        productSetup?.data?.filter?.(
          product => product.type === values.waterProductType?.id && product.status === 'ACTIVE'
        ) || []
      );
    }
    return [];
  }, [productSetup?.data, productSetup.isSuccess, values.waterProductType]);

  React.useEffect(() => {
    if (!values?.isWaterInWorkflow) {
      setFieldValue('isWaterMandatory', false);
    }
  }, [setFieldValue, values?.isWaterInWorkflow]);

  React.useEffect(() => {
    if (!values?.isReturnedMaterialInWorkflow) {
      setFieldValue('isReturnedMaterialMandatory', false);
    }
  }, [setFieldValue, values?.isReturnedMaterialInWorkflow]);

  return (
    <Styled className={cn('general')}>
      <Column flex={1}>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Typography.H3>
              <Localization.Translate stringId="water" data-testid="water-title" />
            </Typography.H3>
            <Components.WaterInWorkflow />
            {values?.isWaterInWorkflow && (
              <div>
                <Components.WaterMandatory />
                <Components.WaterProductType options={productTypeSetup.data} busy={productTypeSetup.isLoading} />
                <Components.DefaultWaterProduct options={waterProducts} busy={productSetup.isLoading} />
              </div>
            )}
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Typography.H3>
              <Localization.Translate stringId="returnedMaterial" data-testid="returnedMaterial-title" />
            </Typography.H3>
            <Components.ReturnedMaterialInWorkflow />
            {values?.isReturnedMaterialInWorkflow && <Components.ReturnedMaterialMandatory />}
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Typography.H3>
              <Localization.Translate stringId="material" data-testid="material-title" />
            </Typography.H3>
            <Components.MaterialServiceInWorkflow />
            <Components.MaterialComplianceEnabled />
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.UnloadingMethodEnabled />
          </Container>
        </Row>
        <Row>
          <Container flex={1} style={{ padding: '15px' }}>
            <Components.ConcreteOnTruckUom options={uomSetup.data} busy={uomSetup.isLoading} />
          </Container>
        </Row>
        <div className="actions" style={{ padding: '15px' }}>
          <Core.Button loading={busy} type="primary" data-testid="save-button" stringId="save" onClick={onSave} />
        </div>
      </Column>
    </Styled>
  );
};
