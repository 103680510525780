import cn from 'classnames';
import { Core, Drawer, Form } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useCreateItem, useMaterialComplianceSetup, useUpdateItem } from '../../../MasterDataProvider';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const MaterialComplianceEditor = () => {
  const { Components, values, mode } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();

  const materialComplianceSetup = useMaterialComplianceSetup();

  const busy = React.useMemo(() => {
    return materialComplianceSetup.isLoading;
  }, [materialComplianceSetup.isLoading]);

  const create = useCreateItem();
  const update = useUpdateItem();

  const editSetupData = React.useCallback(
    item => {
      return update(item);
    },
    [update]
  );

  const saveSetupData = React.useCallback(
    item => {
      return create(item);
    },
    [create]
  );

  const handleSave = React.useCallback(() => {
    return saveSetupData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, saveSetupData, values]);

  const handleEdit = React.useCallback(() => {
    return editSetupData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, editSetupData, values]);

  return (
    <Core.Spinner spin={busy}>
      <Styled className={cn('materialCompliance-editor')}>
        <Components.Id disabled={mode === 'edit'} />
        <Components.Name />
        <Components.Description />
        <Components.ClassType />
        <Components.MinimumValue />
        <Components.MaximumValue />
        <Components.UomCode />
        <Components.Status />
        <div className="actions">
          <div>
            <Components.CancelButton onCancel={closeDrawer} enabled />
            <Components.SaveButton onCreate={handleSave} onUpdate={handleEdit} suppressDisabledStyling />
          </div>
        </div>
      </Styled>
    </Core.Spinner>
  );
};
