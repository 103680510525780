import cn from 'classnames';
import { Form, Localization } from 'connex-cds';
import { isEmpty, omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { FLOW } from '../Acceptance';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const HeaderMobile = ({ flow, submitAcceptanceVisible, signed }) => {
  const {
    Components: { SubmitButton },
    errors,
  } = Form.useFormContext();

  return (
    <Styled className={cn('header')}>
      <div className="left-side">{signed ? <Localization.LocaleSwitcher persist={false} /> : null}</div>
      <div className="right-side">
        {signed ? null : <Localization.LocaleSwitcher persist={false} />}
        {flow === FLOW.ACCEPT_REJECT || (flow === FLOW.SIGNATURE_NO_SIGNATURE && submitAcceptanceVisible) ? (
          <SubmitButton
            stringId="submitAcceptance"
            data-testid="submit-acceptance-button"
            size="small"
            disabledWhenNotDirty={false}
            disabled={!isEmpty(omit(errors, 'ticketEvents'))}
          />
        ) : null}
      </div>
    </Styled>
  );
};
